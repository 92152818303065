const Notifications = () => {
  return (
    <div className="d-flex align-items-center ms-1 ms-lg-3">
      <div
        className="btn btn-icon btn-icon-muted btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px position-relative"
        data-ttz-menu-trigger="click"
        data-ttz-menu-attach="parent"
        data-ttz-menu-placement="bottom-end"
        data-ttz-menu-flip="bottom"
      >
        <i className="fad fa-bell fs-2"></i>
        <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink" />
      </div>
      <div
        className="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px"
        data-ttz-menu="true"
      >
        <div
          className="d-flex flex-column bgi-no-repeat rounded-top bg-primary"
          style={{ backgroundImage: "url('/misc/pattern-1.jpg')" }}
        >
          <h3 className="text-white fw-bold px-9 mt-10 mb-6">
            Notificações
            <span className="fs-5 opacity-75 ps-3">2 mensagens</span>
          </h3>
          <ul className="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">
            <li className="nav-item">
              <a
                className="nav-link text-white opacity-75 opacity-state-100 pb-4 active"
                data-bs-toggle="tab"
                href="#ttz_topbar_notifications_1"
              >
                Alertas
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-white opacity-75 opacity-state-100 pb-4 "
                data-bs-toggle="tab"
                href="#ttz_topbar_notifications_2"
              >
                Atualizações
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link text-white opacity-75 opacity-state-100 pb-4"
                data-bs-toggle="tab"
                href="#ttz_topbar_notifications_3"
              >
                Logs
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content">
          <div
            className="tab-pane fade show active"
            id="ttz_topbar_notifications_1"
            role="tabpanel"
          >
            <div className="scroll-y mh-325px my-5 px-8">
              <div className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-35px me-4">
                    <span className="symbol-label bg-light-primary">
                      <span className="fad fa-party-horn text-primary"></span>
                    </span>
                  </div>
                  <div className="mb-0 me-2">
                    <a
                      href="#"
                      className="fs-6 text-gray-800 text-hover-primary fw-bolder"
                    >
                      Hoje é seu dia 🥳
                    </a>
                    <div className="text-gray-400 fs-4">
                      Parabéns por mais um ano, Wendell
                    </div>
                  </div>
                </div>
                <span className="badge badge-light fs-5">1 hr</span>
              </div>
              <div className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-35px me-4">
                    <span className="symbol-label bg-light-danger">
                      <span className="fad fa-memo-circle-check text-danger"></span>
                    </span>
                  </div>
                  <div className="mb-0 me-2">
                    <a
                      href="#"
                      className="fs-6 text-gray-800 text-hover-primary fw-bolder"
                    >
                      Folha de pagamento gerada
                    </a>
                    <div className="text-gray-400 fs-4">Acesse o documento</div>
                  </div>
                </div>
                <span className="badge badge-light fs-5">2 hrs</span>
              </div>
            </div>
            <div className="py-3 text-center border-top">
              <a
                href="pages/profile/activity.html"
                className="btn btn-color-gray-600 btn-active-color-primary"
              >
                Ver todas
                <span className="fad fa-arrow-right-long ms-2"></span>
              </a>
            </div>
          </div>
          <div
            className="tab-pane fade "
            id="ttz_topbar_notifications_2"
            role="tabpanel"
          >
            <div className="d-flex flex-column px-9">
              <div className="pt-10 pb-0">
                <h3 className="text-dark text-center fw-bolder">
                  Get Pro Access
                </h3>
                <div className="text-center text-gray-600 fw-bold pt-1">
                  Outlines keep you honest. They stoping you from amazing poorly
                  about drive
                </div>
                <div className="text-center mt-5 mb-9">
                  <a
                    href="#"
                    className="btn btn-sm btn-primary px-6"
                    data-bs-toggle="modal"
                    data-bs-target="#ttz_modal_upgrade_plan"
                  >
                    Upgrade
                  </a>
                </div>
              </div>
              <img
                className="mh-200px"
                alt="metronic"
                src="/svg/illustrations/work.svg"
              />
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="ttz_topbar_notifications_3"
            role="tabpanel"
          >
            <div className="scroll-y mh-325px my-5 px-8">
              <div className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center me-2">
                  <span className="w-70px badge badge-light-success me-4">
                    200 OK
                  </span>
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fw-bold"
                  >
                    New order
                  </a>
                </div>
                <span className="badge badge-light fs-5">Just now</span>
              </div>
              <div className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center me-2">
                  <span className="w-70px badge badge-light-danger me-4">
                    500 ERR
                  </span>
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fw-bold"
                  >
                    New customer
                  </a>
                </div>
                <span className="badge badge-light fs-5">2 hrs</span>
              </div>
              <div className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center me-2">
                  <span className="w-70px badge badge-light-success me-4">
                    200 OK
                  </span>
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fw-bold"
                  >
                    Payment process
                  </a>
                </div>
                <span className="badge badge-light fs-5">5 hrs</span>
              </div>
              <div className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center me-2">
                  <span className="w-70px badge badge-light-warning me-4">
                    300 WRN
                  </span>
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fw-bold"
                  >
                    Search query
                  </a>
                </div>
                <span className="badge badge-light fs-5">2 days</span>
              </div>
              <div className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center me-2">
                  <span className="w-70px badge badge-light-success me-4">
                    200 OK
                  </span>
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fw-bold"
                  >
                    API connection
                  </a>
                </div>
                <span className="badge badge-light fs-5">1 week</span>
              </div>
              <div className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center me-2">
                  <span className="w-70px badge badge-light-success me-4">
                    200 OK
                  </span>
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fw-bold"
                  >
                    Database restore
                  </a>
                </div>
                <span className="badge badge-light fs-5">Mar 5</span>
              </div>
              <div className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center me-2">
                  <span className="w-70px badge badge-light-warning me-4">
                    300 WRN
                  </span>
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fw-bold"
                  >
                    System update
                  </a>
                </div>
                <span className="badge badge-light fs-5">May 15</span>
              </div>
              <div className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center me-2">
                  <span className="w-70px badge badge-light-warning me-4">
                    300 WRN
                  </span>
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fw-bold"
                  >
                    Server OS update
                  </a>
                </div>
                <span className="badge badge-light fs-5">Apr 3</span>
              </div>
              <div className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center me-2">
                  <span className="w-70px badge badge-light-warning me-4">
                    300 WRN
                  </span>
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fw-bold"
                  >
                    API rollback
                  </a>
                </div>
                <span className="badge badge-light fs-5">Jun 30</span>
              </div>
              <div className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center me-2">
                  <span className="w-70px badge badge-light-danger me-4">
                    500 ERR
                  </span>
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fw-bold"
                  >
                    Refund process
                  </a>
                </div>
                <span className="badge badge-light fs-5">Jul 10</span>
              </div>
              <div className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center me-2">
                  <span className="w-70px badge badge-light-danger me-4">
                    500 ERR
                  </span>
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fw-bold"
                  >
                    Withdrawal process
                  </a>
                </div>
                <span className="badge badge-light fs-5">Sep 10</span>
              </div>
              <div className="d-flex flex-stack py-4">
                <div className="d-flex align-items-center me-2">
                  <span className="w-70px badge badge-light-danger me-4">
                    500 ERR
                  </span>
                  <a
                    href="#"
                    className="text-gray-800 text-hover-primary fw-bold"
                  >
                    Mail tasks
                  </a>
                </div>
                <span className="badge badge-light fs-5">Dec 10</span>
              </div>
            </div>
            <div className="py-3 text-center border-top">
              <a
                href="pages/profile/activity.html"
                className="btn btn-color-gray-600 btn-active-color-primary"
              >
                View All
                <span className="svg-icon svg-icon-5"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notifications
