import SidebarMenuItemLink from './ItemLink'
import SidebarMenuItemSubAccordion from './ItemSubAccordion'

const SidebarMenuItem = ({
  slug,
  title,
  items,
  isSection,
  isSeparator,
  content,
  className = '',
  render, // Will be deprecated, use the component prop
  component,
  ...rest
}) => {
  if (render) return render()
  if (component) return <>{component}</>

  if (content)
    return (
      <div className={`menu-item ${slug} ${className}`}>
        <div className="menu-content">{content}</div>
      </div>
    )

  if (isSection)
    return (
      <div className={`menu-item pt-5 ${slug} ${className}`}>
        <div className="menu-content">
          <span className="menu-heading fw-bold text-uppercase fs-7">
            {title}
          </span>
        </div>
      </div>
    )

  if (isSeparator)
    return (
      <div className={`menu-item ${slug} ${className}`}>
        <div className="menu-content">
          <div className="separator mx-1 my-3" />
        </div>
      </div>
    )

  if (items)
    return (
      <div
        data-ttz-menu-trigger="click"
        className={`menu-item menu-accordion ${slug} ${className}`}
      >
        <SidebarMenuItemLink title={title} slug={slug} {...rest} />
        <SidebarMenuItemSubAccordion items={items} />
      </div>
    )

  return (
    <div className={`menu-item ${slug} ${className}`}>
      <SidebarMenuItemLink title={title} slug={slug} {...rest} />
    </div>
  )
}

export default SidebarMenuItem
