// Master key
export const MASTER_KEY = '7EE124550D6945B2BEDA82FCA7E4B8F6'

// Allowed selling methods
export const DEFAULT_SELLING_MODE = 'default'
export const MANUAL_SELLING_MODE = 'manual'
export const SELLING_MODES = ['default', 'manual']

// Payment methods
export const PIX = 'pix'
export const BANK_SLIP = 'bank_slip'
export const CREDIT_CARD = 'credit_card'
export const BANK_SLIP_IN_INSTALLMENTS = 'bank_slip_in_installments'
export const ALL_PAYMENT_METHODS = [
  PIX,
  BANK_SLIP,
  CREDIT_CARD,
  BANK_SLIP_IN_INSTALLMENTS
]

// IUGU transactional tax cents
export const IUGU_TRANSACTIONAL_TAX_CENTS = 25

// Min price cents
export const MIN_PRICE_CENTS = 500
export const CREDIT_CARD_MIN_INSTALLMENT_PRICE_CENTS = 500 // By IUGU
export const BANK_SLIP_MIN_INSTALLMENT_PRICE_CENTS = 5000 // By Tootz Payments

// Default mode by Tootz Payments
export const DEFAULT_MODE_FEE_PERCENT = 0.05
export const DEFAULT_MODE_TAX_CENTS = 170 + IUGU_TRANSACTIONAL_TAX_CENTS
export const DEFAULT_MODE_TAX_PERCENT = 0.07

/**
 * TAXES
 */
// Pix
export const IUGU_PIX_TAX_CENTS = 200
export const IUGU_PIX_TAX_PERCENT = 0

export const TTZPAY_PIX_TAX_CENTS = 0
export const TTZPAY_PIX_TAX_PERCENT = 0.02

// Bank slip
export const IUGU_BANK_SLIP_TAX_CENTS = 170
export const IUGU_BANK_SLIP_TAX_PERCENT = 0

export const TTZPAY_BANK_SLIP_TAX_CENTS = IUGU_TRANSACTIONAL_TAX_CENTS + 5 // TTZ will pay IUGU_TRANSACTIONAL_TAX_CENTS at the end of the month
export const TTZPAY_BANK_SLIP_TAX_PERCENT = 0.02

// Credit card
export const IUGU_CREDIT_CARD_TAX_PERCENTS = {
  1: 0.029,
  2: 0.035,
  3: 0.035,
  4: 0.035,
  5: 0.035,
  6: 0.035,
  7: 0.04,
  8: 0.04,
  9: 0.04,
  10: 0.04,
  11: 0.04,
  12: 0.04
}
export const IUGU_CREDIT_CARD_TAX_PERCENT = installment =>
  IUGU_CREDIT_CARD_TAX_PERCENTS[installment]
export const IUGU_CREDIT_CARD_TAX_CENTS = IUGU_TRANSACTIONAL_TAX_CENTS

export const TTZPAY_CREDIT_CARD_TAX_PERCENT = installment =>
  (0.01 + installment * 0.003).toFixed(3)
export const TTZPAY_CREDIT_CARD_TAX_CENTS = 65

// Bank slip with installments
export const IUGU_BANK_SLIP_IN_INSTALLMENTS_TAX_PERCENT = 0
export const IUGU_BANK_SLIP_IN_INSTALLMENTS_TAX_CENTS = installment =>
  installment * IUGU_BANK_SLIP_TAX_CENTS

export const TTZPAY_BANK_SLIP_IN_INSTALLMENTS_TAX_PERCENT = installment =>
  (0.02 + installment * 0.004).toFixed(3)
export const TTZPAY_BANK_SLIP_IN_INSTALLMENTS_TAX_CENTS = installment =>
  180 + installment * 65 + installment * IUGU_TRANSACTIONAL_TAX_CENTS // TTZ will pay IUGU_TRANSACTIONAL_TAX_CENTS at the end of the month
