import axios from 'axios'
import qs from 'qs'
import _ from 'lodash'

axios.defaults.paramsSerializer = params =>
  qs.stringify(params, { arrayFormat: 'brackets' })

// IUGU API
const iuguApi = axios.create({
  baseURL: 'https://api.iugu.com/v1'
})

export { iuguApi }
