const getNavbarMenuItems = user => {
  return [
    // {
    //   slug: "dashboard",
    //   title: "Dashboard",
    //   href: "/",
    // },
    // {
    //   slug: "rotas",
    //   title: "Routes",
    //   items: [
    //     {
    //       slug: "routes1",
    //       title: "Rota 1",
    //       href: "#",
    //       icon: "fad fa-horse",
    //     },
    //     {
    //       slug: "routes2",
    //       title: "Rota 2",
    //       icon: "fad fa-horse",
    //       items: [
    //         {
    //           slug: "routes21",
    //           title: "Rota 2-1",
    //           href: "#",
    //         },
    //         {
    //           slug: "routes22",
    //           title: "Rota 2-2",
    //           href: "#",
    //           condition: user.is_superadmin,
    //         },
    //       ],
    //     },
    //   ],
    // },
  ]
}

export default getNavbarMenuItems
