import React, { createContext, useContext, useState, useEffect } from 'react'

import { getSession, signIn } from 'next-auth/react'

const SessionContext = createContext()

const useSessionContext = () => useContext(SessionContext)

const SessionProvider = ({ session, children }) => {
  const [stateSession, setStateSession] = useState(session)

  const refreshSession = async props => {
    const session = await getSession()

    if (session) {
      await signIn('refresh-session', {
        token: session.accessToken,
        redirect: false,
        ...props
      })
    }
  }

  useEffect(() => {
    setStateSession(session)
  }, [session])

  return (
    <SessionContext.Provider
      value={{
        ...stateSession,
        setSession: setStateSession,
        refreshSession
      }}
    >
      {children}
    </SessionContext.Provider>
  )
}

// eslint-disable-next-line react/display-name
const withSession = Component => props => {
  return (
    <SessionProvider>
      <Component {...props} />
    </SessionProvider>
  )
}

export { useSessionContext, SessionProvider, withSession }
