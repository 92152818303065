import _ from 'lodash'

import { MIN_PRICE_CENTS } from '../core/config'
import { CouponRepository } from '../models/CouponRepository'

const getDiscount = async (accountId, couponCode, userId, totalCents) => {
  const repository = new CouponRepository(accountId)

  if (!couponCode) return 0

  const coupon = await repository.find(couponCode)

  if (!coupon) return 0

  if (coupon.MaxUses > 0 && coupon.Uses.length >= coupon.MaxUses) return 0

  if (coupon.ValidUntil < new Date()) return 0

  if (
    coupon.MaxUsesPerUser > 0 &&
    coupon.Uses.filter(el => el == userId).length >= coupon.MaxUsesPerUser
  )
    return 0

  const maxDiscountCentsForTotal = totalCents - MIN_PRICE_CENTS
  let discountCents = coupon.Discount
  if (coupon.DiscountType == 'percent')
    discountCents = (coupon.Discount / 100) * totalCents

  if (coupon.MaxDiscountCents)
    discountCents = Math.min(discountCents, coupon.MaxDiscountCents)

  return Math.min(discountCents, maxDiscountCentsForTotal)
}

const applyDiscount = async (accountId, couponCode, userId, totalCents) => {
  const discountCents = await getDiscount(
    accountId,
    couponCode,
    userId,
    totalCents
  )

  if (discountCents > 0) {
    const repository = new CouponRepository(accountId)
    const coupon = await repository.find(couponCode)

    await repository.update({
      Id: coupon.Id,
      Uses: [...coupon.Uses, parseInt(userId)]
    })
  }

  return true
}

export default getDiscount
export { getDiscount, applyDiscount }
