import React, { createContext, useContext } from 'react'

import useDataProvider from '../hooks/useDataProvider'

const DataProviderContext = createContext(null)

const useDataProviderContext = () => useContext(DataProviderContext)

const DataProviderProvider = ({ httpClient, children }) => {
  const fetcher = (resource, init) =>
    httpClient.get(resource, init).then(res => res.data)
  const dataProvider = useDataProvider('', httpClient)

  return (
    <DataProviderContext.Provider value={{ httpClient, fetcher, dataProvider }}>
      {children}
    </DataProviderContext.Provider>
  )
}

export { useDataProviderContext, DataProviderProvider }
