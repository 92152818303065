import _ from 'lodash'

const objKeysToCamelCase = obj => {
  if (Array.isArray(obj)) {
    return obj.map(v => objKeysToCamelCase(v))
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [_.camelCase(key)]: objKeysToCamelCase(obj[key])
      }),
      {}
    )
  }
  return obj
}

export default objKeysToCamelCase
