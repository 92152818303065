const TopbarHeaderMenuToggle = () => (
  <div
    className="d-flex align-items-center d-lg-none ms-2 me-n3"
    data-bs-toggle="tooltip"
    title="Mostrar menu"
  >
    <div
      id="ttz_app_header_menu_toggle"
      className="btn btn-icon btn-active-light-primary"
    >
      <span className="svg-icon svg-icon-1">
        <i className="fad fa-bars fs-1" />
      </span>
    </div>
  </div>
)

export default TopbarHeaderMenuToggle
