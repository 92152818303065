import classNames from 'classnames'

const getInputClasses = (
  {
    prefix = 'form-control',
    size,
    solid,
    white,
    flush,
    shadow,
    className = '',
    ...rest
  },
  isInvalid = false
) => {
  const sizeClassName = `${prefix}-${size ? size : 'md'}`
  const classes = classNames(prefix, sizeClassName, className, {
    [`${prefix}-solid`]: solid,
    [`${prefix}-white`]: white,
    [`${prefix}-flush`]: flush,
    [`${prefix}-shadow`]: shadow,
    ['is-invalid']: isInvalid,
    ...rest
  })

  return classes
}

export default getInputClasses
