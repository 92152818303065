import Cookies from 'js-cookie'
import _ from 'lodash'

export const setCookie = (key, value, expiresInSeconds = 172800) => {
  if (typeof window === 'undefined') {
    Cookies.set(key, value, {
      expires: expiresInSeconds / (3600 * 24),
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN
    })
  }
}

export const removeCookie = (
  key,
  domain = process.env.NEXT_PUBLIC_COOKIE_DOMAIN
) => {
  if (typeof window === 'undefined') {
    if (key) {
      Cookies.remove(key, {
        domain
      })
    } else {
      Object.keys(Cookies.get()).forEach(function (cookieName) {
        var neededAttributes = {}
        Cookies.remove(cookieName, neededAttributes)
      })
    }
  }
}

const getCookieFromBrowser = key => Cookies.get(key)

const getCookieFromServer = (key, req) => {
  if (!_.has(req, 'headers.cookie')) return undefined

  const rawCookie = req.headers.cookie
    .split(';')
    .find(c => c.trim().startsWith(`${key}=`))

  if (!rawCookie) return undefined
  return rawCookie.split('=')[1]
}

export const getCookie = (key, req) =>
  typeof window === 'undefined'
    ? getCookieFromServer(key, req)
    : getCookieFromBrowser(key)

const cookie = { setCookie, removeCookie, getCookie }

export default cookie
