/* eslint-disable @next/next/no-img-element */
import Link from 'next/link'

import { useAdminContext } from '../../context/AdminContext'

const MobileLogo = () => {
  const { brand, getLogoIcon } = useAdminContext()
  const logoIcon = getLogoIcon('dark')

  return (
    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
      <Link href="/" className="d-lg-none">
        <img alt={brand.name} src={logoIcon} height="30" />
      </Link>
    </div>
  )
}

export default MobileLogo
