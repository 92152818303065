import _ from 'lodash'

import {
  PIX,
  BANK_SLIP,
  CREDIT_CARD,
  BANK_SLIP_IN_INSTALLMENTS,
  IUGU_PIX_TAX_CENTS,
  IUGU_PIX_TAX_PERCENT,
  TTZPAY_PIX_TAX_CENTS,
  TTZPAY_PIX_TAX_PERCENT,
  IUGU_BANK_SLIP_TAX_CENTS,
  IUGU_BANK_SLIP_TAX_PERCENT,
  TTZPAY_BANK_SLIP_TAX_CENTS,
  TTZPAY_BANK_SLIP_TAX_PERCENT,
  IUGU_CREDIT_CARD_TAX_PERCENT,
  IUGU_CREDIT_CARD_TAX_CENTS,
  TTZPAY_CREDIT_CARD_TAX_PERCENT,
  TTZPAY_CREDIT_CARD_TAX_CENTS,
  IUGU_BANK_SLIP_IN_INSTALLMENTS_TAX_PERCENT,
  IUGU_BANK_SLIP_IN_INSTALLMENTS_TAX_CENTS,
  TTZPAY_BANK_SLIP_IN_INSTALLMENTS_TAX_PERCENT,
  TTZPAY_BANK_SLIP_IN_INSTALLMENTS_TAX_CENTS,
  DEFAULT_MODE_TAX_CENTS,
  DEFAULT_MODE_TAX_PERCENT,
  DEFAULT_MODE_FEE_PERCENT
} from './config'
import { getDefaultModePrice } from './getPrice'
import { getManualModeTaxes } from './getTaxes'
import _calcTax from './utils/_calcTax'

const getManualModeSplit = (netPriceCents, quantity = 1) => {
  const totalNetPriceCents = netPriceCents * (quantity || 1)
  const taxes = getManualModeTaxes(totalNetPriceCents)
  let pixCentsAdjuster = 0
  let bankSlipCentsAdjuster = 0

  if (taxes[PIX] > taxes[BANK_SLIP]) {
    bankSlipCentsAdjuster = taxes[PIX] - taxes[BANK_SLIP]
  } else if (taxes[BANK_SLIP] > taxes[PIX]) {
    pixCentsAdjuster = taxes[BANK_SLIP] - taxes[PIX]
  }

  const pix = _calcTax(totalNetPriceCents, [
    { value: TTZPAY_PIX_TAX_CENTS, type: 'cents' },
    { value: TTZPAY_PIX_TAX_PERCENT, type: 'percent' }
  ])
  const bankSlip = _calcTax(totalNetPriceCents, [
    { value: TTZPAY_BANK_SLIP_TAX_CENTS, type: 'cents' },
    { value: TTZPAY_BANK_SLIP_TAX_PERCENT, type: 'percent' }
  ])

  const split = {
    [PIX]: pix + pixCentsAdjuster,
    [BANK_SLIP]: bankSlip + bankSlipCentsAdjuster,
    [CREDIT_CARD]: {},
    [BANK_SLIP_IN_INSTALLMENTS]: {}
  }

  _.range(1, 13).forEach(installment => {
    split[CREDIT_CARD][installment] = _calcTax(totalNetPriceCents, [
      { value: TTZPAY_CREDIT_CARD_TAX_CENTS, type: 'cents' },
      { value: TTZPAY_CREDIT_CARD_TAX_PERCENT(installment), type: 'percent' }
    ])
    if (installment > 1) {
      split[BANK_SLIP_IN_INSTALLMENTS][installment] = _calcTax(
        totalNetPriceCents,
        [
          {
            value: TTZPAY_BANK_SLIP_IN_INSTALLMENTS_TAX_CENTS(installment),
            type: 'cents'
          },
          {
            value: TTZPAY_BANK_SLIP_IN_INSTALLMENTS_TAX_PERCENT(installment),
            type: 'percent'
          }
        ]
      )
    }
  })

  return split
}

const getDefaultModeSplit = (netPriceCents, quantity = 1) => {
  const totalNetPriceCents = netPriceCents * (quantity || 1)
  const price = getDefaultModePrice(totalNetPriceCents)

  const taxes = {
    [PIX]:
      price.inCashCents -
      Math.floor(price.inCashCents * IUGU_PIX_TAX_PERCENT) -
      IUGU_PIX_TAX_CENTS -
      totalNetPriceCents,
    [BANK_SLIP]:
      price.inCashCents -
      Math.floor(price.inCashCents * IUGU_BANK_SLIP_TAX_PERCENT) -
      IUGU_BANK_SLIP_TAX_CENTS -
      totalNetPriceCents,
    [CREDIT_CARD]: {},
    [BANK_SLIP_IN_INSTALLMENTS]: {}
  }

  _.range(1, 13).forEach(installment => {
    taxes[CREDIT_CARD][installment] =
      price.inInstallmentsCents[CREDIT_CARD][installment] -
      Math.floor(
        price.inInstallmentsCents[CREDIT_CARD][installment] *
          IUGU_CREDIT_CARD_TAX_PERCENT(installment)
      ) -
      IUGU_CREDIT_CARD_TAX_CENTS -
      totalNetPriceCents

    if (installment > 1) {
      taxes[BANK_SLIP_IN_INSTALLMENTS][installment] =
        price.inInstallmentsCents[BANK_SLIP_IN_INSTALLMENTS][installment] -
        Math.floor(
          price.inInstallmentsCents[BANK_SLIP_IN_INSTALLMENTS][installment] *
            IUGU_BANK_SLIP_IN_INSTALLMENTS_TAX_PERCENT
        ) -
        IUGU_BANK_SLIP_IN_INSTALLMENTS_TAX_CENTS(installment) -
        totalNetPriceCents
    }
  })

  return taxes
}

const getSplit = (
  sellingMode,
  netPriceCents,
  quantity = 1,
  paymentMethod,
  installments
) => {
  const [isDefaultMode, isManualMode] = [
    sellingMode == 'default',
    sellingMode == 'manual'
  ]

  let split = null

  if (isManualMode) split = getManualModeSplit(netPriceCents, quantity)
  else split = getDefaultModeSplit(netPriceCents, quantity)

  if (paymentMethod) {
    if ([PIX, BANK_SLIP].includes(paymentMethod)) split = split[paymentMethod]
    else if ([CREDIT_CARD, BANK_SLIP_IN_INSTALLMENTS].includes(paymentMethod))
      split = split[paymentMethod][installments]
  }

  return split
}

export default getSplit
export { getManualModeSplit, getDefaultModeSplit }
