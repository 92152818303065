import _ from 'lodash'

const PaginationToolbar = ({
  isLoading,
  perPage,
  totalCount,
  onSelectPerPage
}) => {
  const perPageOptions =
    totalCount <= 12
      ? [perPage]
      : _.sortedUniq(_.uniq([12, 16, 18, perPage]), true)

  const handleChange = event => onSelectPerPage(event.target.value)

  return (
    <div className="d-flex align-items-center mt-4 mt-md-0">
      {isLoading && (
        <div className="d-flex align-items-center mr-2">
          <i className="fad fa-spinner-third fa-spin mr-1"></i>
          <div className="mr-2 text-muted d-none d-md-inline-block">
            Carregando...
          </div>
        </div>
      )}
      <select
        className={
          'form-control form-control-sm font-weight-bold me-4 border-0 bg-light'
        }
        onChange={handleChange}
        value={perPage}
        style={{ width: '75px' }}
      >
        {perPageOptions.map(option => (
          <option
            key={`perPageOption${option}`}
            value={option}
            className={`btn ${option == perPage ? 'active' : ''}`}
          >
            {option}
          </option>
        ))}
      </select>
      {perPage && totalCount && (
        <span className="text-muted">
          Mostrando {totalCount < perPage ? totalCount : perPage} de{' '}
          {totalCount} registros
        </span>
      )}
    </div>
  )
}

export default PaginationToolbar
