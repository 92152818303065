import * as dynamoose from 'dynamoose'

export const CouponSchema = new dynamoose.Schema(
  {
    AccountId: {
      type: String,
      hashKey: true,
      required: true
    },
    Id: {
      type: String,
      rangeKey: true,
      required: true
    },
    Uses: {
      type: Array,
      required: true,
      schema: [Number],
      default: []
    },
    MaxUses: {
      type: Number,
      required: true,
      default: 0
    },
    MaxUsesPerUser: {
      type: Number,
      required: true,
      default: 0
    },
    ValidUntil: {
      type: Date,
      required: false
    },
    Discount: {
      type: Number,
      required: true,
      default: 0
    },
    DiscountType: {
      type: String,
      required: true,
      default: 'percent'
    },
    MaxDiscountCents: {
      type: Number,
      required: true,
      default: 0
    }
  },
  {
    timestamps: {
      createdAt: 'CreateDate',
      updatedAt: 'UpdateDate'
    }
  }
)
