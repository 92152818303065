import { signOut } from "next-auth/react";

const getQuickUserMenuItems = (user) => {
  return [
    {
      slug: "account-settings",
      title: "Minha Conta",
      href: "/minha-conta/configuracoes",
    },
    // {
    // slug: "my-company",
    // title: "Minha Empresa",
    // href: "/account/company",
    // badge: (
    //   <span className="menu-badge">
    //     <span className="badge badge-light-danger badge-circle fw-bolder fs-7">
    //       3
    //     </span>
    //   </span>
    // ),
    //},
    //{
    // slug: "my-pla",
    // title: "Meu Plano",
    // href: "/account/plan",
    // badge: (
    //   <span className="menu-badge">
    //     <span className="badge badge-light-danger badge-circle fw-bolder fs-7">
    //       3
    //     </span>
    //   </span>
    // ),
    //},
    {
      slug: "separator1",
      isSeparator: true,
    },
    // {
    //   slug: "links1",
    //   title: "Links",
    //   items: [{ slug: "links1", title: "Links", href: "#" }],
    // },
    {
      slug: "sign-out",
      title: "Sair",
      href: "/auth/sign-out",
    },
  ];
};

export default getQuickUserMenuItems;
