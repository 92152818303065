import { useState, useEffect } from 'react'

import _ from 'lodash'

import { useAdminContext } from '../../../context/AdminContext'

export const ColorModes = () => {
  const { enableColorModes } = useAdminContext()
  const [currentTheme, setCurrentTheme] = useState()
  const getStoredTheme = () => localStorage.getItem('theme')
  const setStoredTheme = theme => localStorage.setItem('theme', theme)

  const getPreferredTheme = () => {
    const storedTheme = getStoredTheme()

    if (storedTheme) return storedTheme

    return window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  }

  const setTheme = theme => {
    if (
      theme === 'auto' &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      document.documentElement.setAttribute('data-bs-theme', 'dark')
    } else {
      document.documentElement.setAttribute('data-bs-theme', theme)
    }
  }

  useEffect(() => {
    if (enableColorModes && currentTheme) {
      setTheme(currentTheme)
      setStoredTheme(currentTheme)
    }
  }, [enableColorModes, currentTheme])

  useEffect(() => {
    if (enableColorModes) {
      setCurrentTheme(getPreferredTheme())

      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', () => {
          const storedTheme = getStoredTheme()

          if (storedTheme !== 'light' && storedTheme !== 'dark')
            setCurrentTheme(getPreferredTheme())
        })
    }
  }, [enableColorModes])

  if (!enableColorModes) return <></>
  return (
    <div className="app-navbar-item ms-1 ms-md-4">
      <div
        className="btn btn-icon btn-icon-white btn-active-light btn-active-color-primary w-30px h-30px w-md-40px h-md-40px position-relative"
        data-ttz-menu-trigger="{default: 'click', lg: 'hover'}"
        data-ttz-menu-attach="parent"
        data-ttz-menu-placement="bottom-end"
      >
        <i className="fal fa-sun fs-2 theme-light-show"></i>
        <i className="fal fa-moon-stars fs-2 theme-dark-show"></i>
      </div>

      <div
        className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"
        data-ttz-menu="true"
      >
        <div className="menu-item px-3 my-0">
          <a
            href="#"
            className={`menu-link px-3 py-2 ${
              currentTheme == 'light' ? 'active' : ''
            }`}
            onClick={e => {
              e.preventDefault()

              setCurrentTheme('light')
            }}
          >
            <span className="menu-icon" data-ttz-element="icon">
              <i className="fal fa-sun fs-2"></i>
            </span>
            <span className="menu-title">Light</span>
          </a>
        </div>

        <div className="menu-item px-3 my-0">
          <a
            href="#"
            className={`menu-link px-3 py-2 ${
              currentTheme == 'dark' ? 'active' : ''
            }`}
            onClick={e => {
              e.preventDefault()

              setCurrentTheme('dark')
            }}
          >
            <span className="menu-icon" data-ttz-element="icon">
              <i className="fal fa-moon fs-2" />
            </span>
            <span className="menu-title">Dark</span>
          </a>
        </div>

        <div className="menu-item px-3 my-0">
          <a
            href="#"
            className={`menu-link px-3 py-2 ${
              currentTheme == 'auto' ? 'active' : ''
            }`}
            onClick={e => {
              e.preventDefault()

              setCurrentTheme('auto')
            }}
          >
            <span className="menu-icon" data-ttz-element="icon">
              <i className="fal fa-desktop fs-2"></i>
            </span>
            <span className="menu-title">System</span>
          </a>
        </div>
      </div>
    </div>
  )
}
