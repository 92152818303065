import classNames from 'classnames'

import { ConditionalWrapper } from '@tootz/react-admin/components'

const Overlay = ({
  block,
  show,
  active,
  bg = 'white',
  opacity = '75',
  cursor = 'wait',
  layer,
  children
}) => {
  const classes = classNames('overlay', {
    'overlay-hidden': !block && !show,
    'overlay-block': block,
    'overlay-show': show,
    [`cursor-${cursor}`]: !show
  })

  const layerClasses = classNames(
    `overlay-layer card-rounded bg-${bg} bg-opacity-${opacity}`
  )

  const wrapper = children => (
    <div className={classes}>
      <div className="overlay-wrapper">{children}</div>
      <div className={layerClasses}>{layer}</div>
    </div>
  )

  return (
    <ConditionalWrapper condition={active} wrapper={wrapper}>
      {children}
    </ConditionalWrapper>
  )
}

export default Overlay
