const getAsideMenuItems = user => {
  return [
    // {
    //   slug: "management",
    //   title: "Gestão",
    //   isSection: true,
    // },
    // {
    //   slug: "dashboard",
    //   title: "Dashboard",
    //   icon: "fad fa-th",
    //   href: "/",
    // },
    // {
    //   slug: 'clients',
    //   title: 'Clientes',
    //   icon: 'fad fa-user-tie',
    //   href: '/clientes'
    // },
    // {
    //   slug: "communication",
    //   title: "Comunicação",
    //   icon: "fad fa-megaphone",
    //   href: "/communication/posts",
    // },
    {
      slug: 'company',
      title: 'Administração',
      isSection: true
    },
    {
      slug: 'plans',
      title: 'Planos',
      icon: 'fad fa-tally-3',
      href: '/pagamentos/planos'
    },
    {
      slug: 'coupons',
      title: 'Cupons',
      icon: 'fad fa-ticket',
      href: '/cupons'
    }
    // {
    //   slug: 'terms',
    //   title: 'Termos',
    //   icon: 'fad fa-file-signature',
    //   href: '/termos'
    // },
    // {
    //   slug: 'reports',
    //   title: 'Relatórios',
    //   icon: 'fad fa-file-chart-column',
    //   href: '/relatorios'
    // },
    // {
    //   slug: 'users',
    //   title: 'Usuários',
    //   icon: 'fad fa-user-group-crown',
    //   href: '/usuarios'
    // },
    // {
    //   slug: 'support',
    //   title: 'Suporte',
    //   isSection: true
    // },
    // {
    //   slug: 'tickets',
    //   title: 'Tickets',
    //   icon: 'fad fa-circle-question',
    //   href: '/suporte/tickets'
    // }
    // {
    //   slug: "section1",
    //   title: "Section",
    //   isSection: true,
    // },
    // {
    //   slug: "rotas",
    //   title: "Routes",
    //   icon: "fad fa-horse",
    //   items: [
    //     {
    //       slug: "routes1",
    //       title: "Rota 1",
    //       href: "#",
    //     },
    //     {
    //       slug: "routes2",
    //       title: "Rota 2",
    //       items: [
    //         {
    //           slug: "routes21",
    //           title: "Rota 2-1",
    //           href: "#",
    //         },
    //         {
    //           slug: "routes22",
    //           title: "Rota 2-2",
    //           href: "#",
    //           condition: user.is_superadmin,
    //         },
    //       ],
    //     },
    //   ],
    // },
  ]
}

export default getAsideMenuItems
