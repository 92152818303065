import React from 'react'

import classNames from 'classnames'
import _ from 'lodash'
import Link from 'next/link'

const Card = ({
  children,
  shadow,
  stretch,
  dashed,
  className = '',
  bg,
  flush = false,
  href,
  openLinkInNewTab,
  ...rest
}) => {
  const classes = classNames(
    'card',
    {
      // 'h-100': stretch && !_.isString(stretch),
      [`card-stretch${_.isString(stretch) ? '-' + stretch : ''}`]: stretch,
      'card-dashed': dashed,
      'card-flush': flush,
      [`shadow-${shadow}`]: shadow,
      [`bg-${bg}`]: bg,
      'cursor-pointer': _.has(rest, 'onClick')
    },
    className
  )

  const withLink = (condition, children) =>
    condition ? (
      <Link
        href={href}
        className={classes}
        target={openLinkInNewTab ? '_blank' : '_self'}
        {...rest}
      >
        {children}
      </Link>
    ) : (
      <div className={classes} {...rest}>
        {children}
      </div>
    )

  return withLink(!!href, children)
}

const Header = ({ noBorder, className = '', children, ...rest }) => {
  const classes = classNames('card-header', className, {
    'border-0': noBorder
  })

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  )
}

const Title = ({ className = '', children, ...rest }) => (
  <h3
    className={`card-title flex-column align-items-start ${className}`}
    {...rest}
  >
    {children}
  </h3>
)

const Toolbar = ({ children, className = '', ...rest }) => (
  <div className={`card-toolbar my-0 ${className}`} {...rest}>
    {children}
  </div>
)

const Body = ({ children, className = '', ...rest }) => (
  <div className={`card-body  ${className}`} {...rest}>
    {children}
  </div>
)

const Footer = ({ noBorder, children, className = '', ...rest }) => {
  const classes = classNames('card-footer', className, {
    'border-0': noBorder
  })

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  )
}

Card.Header = Header
Card.Title = Title
Card.Toolbar = Toolbar
Card.Body = Body
Card.Footer = Footer

export default Card
