import _ from 'lodash'
import qs from 'qs'
import { useQuery } from '@tanstack/react-query'

import { useDataProviderContext } from '@tootz/react-admin'

import getQuery from '../services/getQuery'
import _handleDelete from '../services/handleDelete'
import objKeysToSnakeCase from '../utils/objKeysToSnakeCase'

const useRecords = (
  resource,
  queryParams = {},
  options = {
    refetchInterval: 60000,
    refetchIntervalInBackground: 180000,
    retry: false,
    retryDelay: attempt =>
      Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000)
  },
  shouldFetch = true
) => {
  let r = resource

  if (_.isObject(resource)) {
    r = resource.r || resource.resource
    options = resource.options || {
      refetchInterval: 60000,
      refetchIntervalInBackground: 180000,
      retry: false,
      retryDelay: attempt =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000)
    }
    queryParams = resource.queryParams || {}
    shouldFetch =
      typeof resource.shouldFetch == 'undefined' || resource.shouldFetch
  } else {
    if (typeof shouldFetch == 'undefined') shouldFetch = true
  }

  const readyToFetch = shouldFetch && !(!r || r.includes('undefined'))
  const { pagination, sort, filter, params } = objKeysToSnakeCase(queryParams)
  const query = {
    ...(getQuery({ pagination, sort, filter: _.pickBy(filter) }) || {}),
    ...(params || {})
  }
  const path = r
    ? `${r}?${qs.stringify(query, { arrayFormat: 'brackets' })}`
    : null
  const { isLoading, isError, isSuccess, data, refetch, ...rest } = useQuery({
    queryKey: [path],
    enabled: !!readyToFetch,
    ...options
  })
  const records = data?.records || []
  const isEmpty = isSuccess && data && records && records.length == 0
  const supInfo = _.omit(data, ['records', 'pagination'])

  const { dataProvider } = useDataProviderContext()
  const handleDelete = (id, config) =>
    _handleDelete(r, id, config, dataProvider)

  return {
    records,
    ...supInfo,
    pagination: data?.pagination,
    isLoading,
    isError,
    isSuccess,
    isEmpty,
    loadWrapperConfig: { isLoading, isError, isSuccess, isEmpty },
    handleDelete,
    mutate: refetch, // Mutate is deprecated, use refetch instead
    refetch,
    ...rest
  }
}

export default useRecords
export { getQuery }
