import { useAdminContext } from '../../context/AdminContext'

const SidebarFooter = () => {
  const {
    sidebar: { footer }
  } = useAdminContext()

  return (
    <div
      id="ttz_app_sidebar_footer"
      className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6"
    >
      {footer}
    </div>
  )
}

export default SidebarFooter
