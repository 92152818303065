import React, { useEffect, useState } from 'react'

import {
  Hydrate,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'
import TimeAgo from 'javascript-time-ago'
import pt from 'javascript-time-ago/locale/pt'
import _ from 'lodash'
import { Settings } from 'luxon'
import { SessionProvider } from 'next-auth/react'
import TagManager from 'react-gtm-module'

import AuthGuard from '../domain/AuthGuard'
import { api } from '../services/api'

import '../styles/main.scss'

// Luxon config
Settings.defaultZone = 'America/Sao_Paulo'
Settings.defaultLocale = 'pt-BR'

const defaultQueryFn = async ({ queryKey }) => {
  const { data } = await api.get(queryKey[0])
  return data
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      refetchInterval: 0,
      refetchIntervalInBackground: 60000,
      retry: true,
      refetchOnMount: true,
      retryDelay: attempt =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      queryFn: defaultQueryFn
    }
  }
})

const App = ({ Component, pageProps: { session, ...pageProps } }) => {
  useEffect(() => {
    // Google Tag Manager
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID })

    // React Time Ago
    TimeAgo.setDefaultLocale(pt)
    TimeAgo.addLocale(pt)

    // Resize Observer Polyfill
    if (typeof window !== 'undefined' && !window.ResizeObserver) install()
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <SessionProvider
          staleTime={5 * 60}
          clientMaxAge={5 * 60}
          refetchInterval={0}
          session={session}
        >
          <AuthGuard isPublic={Component.isPublic}>
            <Component {...pageProps} />
          </AuthGuard>
        </SessionProvider>
      </Hydrate>
    </QueryClientProvider>
  )
}

export default App
