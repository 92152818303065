import Link from 'next/link'
import { useRouter } from 'next/router'

import SidebarMenuItemBullet from './ItemBullet'
import SidebarMenuItemIcon from './ItemIcon'
import SidebarMenuItemTitle from './ItemTitle'

const SidebarMenuItemLink = ({
  slug,
  href,
  path,
  target = '_self',
  icon,
  title,
  onClick,
  ...rest
}) => {
  const router = useRouter()
  const hrefOrPath = href || path
  const isActive =
    router.pathname == hrefOrPath ||
    (hrefOrPath !== '/' && router.pathname.startsWith(hrefOrPath))

  if (hrefOrPath)
    return (
      <Link
        href={hrefOrPath}
        className={`menu-link menu-link-${slug} ${isActive ? 'active' : ''}`}
        target={target}
      >
        {icon ? <SidebarMenuItemIcon icon={icon} /> : <SidebarMenuItemBullet />}
        <SidebarMenuItemTitle title={title} {...rest} />
      </Link>
    )

  if (onClick && _.isFunction(onClick))
    return (
      <span
        className={`menu-link menu-link-${slug} ${isActive ? 'active' : ''}`}
        onClick={onClick}
      >
        {icon ? <SidebarMenuItemIcon icon={icon} /> : <SidebarMenuItemBullet />}
        <SidebarMenuItemTitle title={title} {...rest} />
      </span>
    )

  return (
    <span className={`menu-link menu-link-${slug}`}>
      {icon ? <SidebarMenuItemIcon icon={icon} /> : <SidebarMenuItemBullet />}
      <SidebarMenuItemTitle title={title} {...rest} />
      <span className="menu-arrow" />
    </span>
  )
}

export default SidebarMenuItemLink
