import React, { useState } from 'react'

import Modal from 'react-bootstrap/Modal'

const ModalHeader = ({
  children,
  className = '',
  hideButtonClassName = '',
  hideButtonIconClassName = '',
  onHide,
  ...rest
}) => (
  <Modal.Header
    className={`flex-wrap text-center border-0 ${className}`}
    {...rest}
  >
    {onHide && (
      <div className="d-flex w-100 justify-content-end border-0 pb-0 position-relative">
        <button
          type="button"
          className={`btn btn-sm btn-icon btn-active-color-primary position-absolute top-0 end-0 ${hideButtonClassName}`}
          onClick={onHide}
        >
          <i className={`fas fa-xmark-large ${hideButtonIconClassName}`}></i>
        </button>
      </div>
    )}
    <div className="d-flex flex-column m-2 m-xl-4 w-100 pt-3">{children}</div>
  </Modal.Header>
)

const ModalIcon = ({ className = '', ...rest }) => (
  <i className={`fs-2x mb-4 ${className}`} {...rest} />
)

const ModalTitle = ({ className = '', children, ...rest }) => (
  <Modal.Title
    className={`${className}`}
    {...rest}
    as={({ children }) => (
      <span className={`h2 mb-2 d-block ${className}`}>{children}</span>
    )}
  >
    {children}
  </Modal.Title>
)

const ModalSubtitle = ({ children, ...rest }) => (
  <div className="text-muted fw-bold fs-6" {...rest}>
    {children}
  </div>
)

const ModalBody = ({ className = '', noPaddings, children, ...rest }) => (
  <Modal.Body
    className={`modal-body ${
      noPaddings ? 'p-0' : 'px-10 px-lg-15 pb-10'
    }  scroll-y--disabled  ${className}`}
    {...rest}
  >
    {children}
  </Modal.Body>
)

const ModalFooter = ({ className = '', noPaddings, children, ...rest }) => (
  <Modal.Footer
    className={`border-0 justify-content-center pt-0 ${
      noPaddings ? 'p-0' : 'px-10 px-lg-15 pb-lg-10'
    } ${className}`}
    {...rest}
  >
    {children}
  </Modal.Footer>
)

const TTZModal = ({ children, className = '', ...rest }) => (
  <Modal className={`ttz-modal ${className}`} {...rest}>
    {children}
  </Modal>
)

const TTZEasyModalTrigger = ({ children, className = '', ...rest }) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <button onClick={() => setShow(true)}>{children}</button>
      <Modal
        className={`ttz-modal ${className}`}
        contentClassName="rounded"
        show={show}
        {...rest}
      >
        asdasd
      </Modal>
    </>
  )
}

TTZModal.Header = ModalHeader
TTZModal.Icon = ModalIcon
TTZModal.Title = ModalTitle
TTZModal.Subtitle = ModalSubtitle
TTZModal.SubTitle = ModalSubtitle
TTZModal.Body = ModalBody
TTZModal.Footer = ModalFooter

export default TTZModal
export { TTZEasyModalTrigger as EasyModalTrigger }
