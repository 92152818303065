import _ from 'lodash'

import { filterMenuItemsByCondition } from '../../../helpers'
import TopbarHeaderMenuToggle from '../MobileMenuToggle'
import { useAdminContext } from '../../../context/AdminContext'

import TopBarItem from './Item'

const Topbar = () => {
  const { header } = useAdminContext()

  return (
    <div className="app-navbar flex-shrink-0" style={{ flexBasis: '1px' }}>
      {header?.topbarItems &&
        filterMenuItemsByCondition(header.topbarItems).map(item => (
          <TopBarItem key={item.slug} {...item} />
        ))}

      {header?.navbarMenuItems?.length > 0 && <TopbarHeaderMenuToggle />}
    </div>
  )
}

export default Topbar
