import * as Sentry from '@sentry/nextjs'
import _ from 'lodash'

import { BANK_SLIP, BANK_SLIP_IN_INSTALLMENTS } from '../../../core/config'
import InvoiceError from '../../../services/customErrors/InvoiceError'
import { iuguApi } from '../api'

/**
 * Criar fatura
 *
 * https://dev.iugu.com/reference/criar-fatura
 */
const createIuguInvoice = async ({
  
  apiToken,
  order_id = null,
  payment_method,
  due_date,
  payer,
  items,
  return_url,
  expired_url,
  custom_variables,
  discount_cents = 0,
  ensure_workday_due_date = true,
  max_installments_value = null,
  customer_id = null,
  fines = false,
  // ignore_canceled_email = false,
  // ignore_due_email = false,
  splits,
  email,
}) => {
  let payable_with = _.isArray(payment_method)
    ? payment_method
    : [payment_method]
  payable_with = payable_with.map(pm =>
    pm == BANK_SLIP_IN_INSTALLMENTS ? BANK_SLIP : pm
  )
  const isBankSlip = _.isEqual(payable_with, [BANK_SLIP])

  let data = {
    email: payer ? payer.email : email,
    due_date,
    ensure_workday_due_date,
    items,
    return_url,
    expired_url,
    ignore_due_email: !isBankSlip,
    ignore_canceled_email: false,
    fines,
    discount_cents,
    customer_id,
    payable_with,
    custom_variables,
    external_reference: order_id,
    payer,
    max_installments_value,
    splits
  }

  try {
    const createIuguInvoiceRes = await iuguApi.post(
      `/invoices?api_token=${apiToken}`,
      data
    )
    const invoice = { ...createIuguInvoiceRes.data, splits }

    return invoice
  } catch (error) {
    if (process.env.NODE_ENV == 'development')
      console.log('IUGU_CREATE_INVOICE', error)

    const errorObject = _.has(error, 'response.data.errors')
      ? new InvoiceError(_.get(error, 'response.data.errors'))
      : new Error(error)

    Sentry.captureException(errorObject)

    throw errorObject
  }
}

export default createIuguInvoice
