import _ from 'lodash'

import {
  PIX,
  BANK_SLIP,
  CREDIT_CARD,
  BANK_SLIP_IN_INSTALLMENTS
} from './config'
import { getManualModeTaxes, getDefaultModeTaxes } from './getTaxes'

const getManualModePrice = (netPriceCents, quantity = 1) => {
  const totalNetPriceCents = netPriceCents * (quantity || 1)
  const taxes = getManualModeTaxes(totalNetPriceCents)
  const inCashCents =
    totalNetPriceCents + Math.max(taxes[PIX], taxes[BANK_SLIP])
  const inInstallmentsCents = {
    [CREDIT_CARD]: {},
    [BANK_SLIP_IN_INSTALLMENTS]: {}
  }

  Object.keys(taxes[CREDIT_CARD]).forEach(installment => {
    inInstallmentsCents[CREDIT_CARD][installment] =
      totalNetPriceCents + _.get(taxes[CREDIT_CARD], installment, 0)
  })

  Object.keys(taxes[BANK_SLIP_IN_INSTALLMENTS]).forEach(installment => {
    inInstallmentsCents[BANK_SLIP_IN_INSTALLMENTS][installment] =
      totalNetPriceCents +
      _.get(taxes[BANK_SLIP_IN_INSTALLMENTS], installment, 0)
  })

  const getPaymentMethodCents = (paymentMethod, installments) => {
    if ([PIX, BANK_SLIP].includes(paymentMethod)) return inCashCents
    else if ([CREDIT_CARD, BANK_SLIP_IN_INSTALLMENTS].includes(paymentMethod))
      return inInstallmentsCents[paymentMethod][installments]
  }

  return {
    cents: inCashCents,
    inCashCents,
    inInstallmentsCents,
    getPaymentMethodCents
  }
}

const getDefaultModePrice = (netPriceCents, quantity = 1) => {
  const totalNetPriceCents = netPriceCents * (quantity || 1)
  const taxes = getDefaultModeTaxes(totalNetPriceCents)
  const inCashCents = totalNetPriceCents + taxes[PIX]
  const inInstallmentsCents = {
    [CREDIT_CARD]: {},
    [BANK_SLIP_IN_INSTALLMENTS]: {}
  }

  Object.keys(taxes[CREDIT_CARD]).forEach(installment => {
    inInstallmentsCents[CREDIT_CARD][installment] =
      (totalNetPriceCents + _.get(taxes[CREDIT_CARD], installment, 0)) *
      (quantity || 1)
  })

  Object.keys(taxes[BANK_SLIP_IN_INSTALLMENTS]).forEach(installment => {
    inInstallmentsCents[BANK_SLIP_IN_INSTALLMENTS][installment] =
      totalNetPriceCents +
      _.get(taxes[BANK_SLIP_IN_INSTALLMENTS], installment, 0)
  })

  const cents = totalNetPriceCents + _.get(taxes[CREDIT_CARD], 1, 0)

  const getPaymentMethodCents = (paymentMethod, installments) => {
    if ([PIX, BANK_SLIP].includes(paymentMethod)) return inCashCents
    else if ([CREDIT_CARD, BANK_SLIP_IN_INSTALLMENTS].includes(paymentMethod))
      return inInstallmentsCents[paymentMethod][installments]
  }

  return {
    cents,
    saleCents: inCashCents,
    inCashCents,
    inInstallmentsCents,
    getPaymentMethodCents
  }
}

const getPrice = (sellingMode, netPriceCents, quantity = 1) => {
  const [isDefaultMode, isManualMode] = [
    sellingMode == 'default',
    sellingMode == 'manual'
  ]

  if (isManualMode) return getManualModePrice(netPriceCents, quantity || 1)
  else return getDefaultModePrice(netPriceCents, quantity || 1)
}

export default getPrice
export { getManualModePrice, getDefaultModePrice }
