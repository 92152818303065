const CONNECTORS = [
  'de',
  'di',
  'do',
  'da',
  'dos',
  'das',
  'dello',
  'della',
  'dalla',
  'dal',
  'del',
  'e',
  'em',
  'na',
  'no',
  'nas',
  'nos',
  'van',
  'von',
  'y',
  '-',
  ','
]

const truncateName = (name = '', length) => {
  if (!name) return ''
  const nameArray = name.trim().split(' ')
  let truncatedName = nameArray[0]

  for (let i = 1; i < nameArray.length; i++) {
    const tempTruncatedName = [truncatedName, nameArray[i]].join(' ')

    if (tempTruncatedName.length <= length) truncatedName = tempTruncatedName
    else break
  }

  CONNECTORS.forEach(
    connector =>
      (truncatedName = truncatedName.replace(
        new RegExp(` ${connector}$`, 'g'),
        ''
      ))
  )

  return truncatedName.trim()
}

export default truncateName
