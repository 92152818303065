import { useEffect, useState } from 'react'

import classNames from 'classnames'

import ConditionalWrapper from './ConditionalWrapper'

const Drawer = ({
  minWidth = 1200,
  start,
  end,
  top,
  bottom,
  fullWidth,
  open: propsOpen,
  onClose,
  children
}) => {
  const [windowWidth, setWindowWidth] = useState(0)
  const [open, setOpen] = useState(false)
  const enabled = windowWidth <= minWidth
  const classes = classNames('drawer', {
    'drawer-start': start,
    'drawer-end': end,
    'drawer-top': top,
    'drawer-bottom': bottom,
    'drawer-on': open,
    'drawer-fullwidth': fullWidth
  })
  const handleResize = () => setWindowWidth(window.innerWidth)
  const handleClose = () => {
    setOpen(false)
    onClose && onClose()
  }

  useEffect(() => {
    let overlay = document.querySelector('.drawer-overlay')

    if (open) {
      if (!overlay) {
        overlay = document.createElement('div')
        overlay.classList.add('drawer-overlay')
      }

      overlay.addEventListener('click', handleClose)
      document.body.appendChild(overlay)
    } else overlay && document.body.removeChild(overlay)

    return () => overlay && document.body.removeChild(overlay)
  }, [open])

  useEffect(() => {
    setOpen(propsOpen)
  }, [propsOpen])

  useEffect(() => {
    if (!enabled) setOpen(false)
  }, [enabled])

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()
  }, [])

  return (
    <ConditionalWrapper
      condition={enabled}
      wrapper={children => (
        <div className={classes} onClick={handleClose}>
          {children}
        </div>
      )}
    >
      {children}
    </ConditionalWrapper>
  )
}

export default Drawer
