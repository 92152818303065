import { useAdminContext } from '../../context/AdminContext'
import SidebarMobileToggle from '../Sidebar/MobileToggle'

import MobileLogo from './MobileLogo'
import Navbar from './Navbar'
import Topbar from './Topbar'

const Header = () => {
  const { header, sidebar } = useAdminContext()

  return (
    <div id="ttz_app_header" className="app-header">
      <div
        id="ttz_app_header_container"
        className={`app-container container${
          header?.containerFluid ? '-fluid' : ''
        } d-flex align-items-stretch justify-content-between`}
      >
        {!sidebar.disabled && <SidebarMobileToggle />}
        <MobileLogo />
        <div
          id="ttz_app_header_wrapper"
          className="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
        >
          <Navbar />
          {header?.center}
          <Topbar />
        </div>
      </div>
    </div>
  )
}

export default Header
