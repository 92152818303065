import { useAdminContext } from '../../../context/AdminContext'
import { filterMenuItemsByCondition } from '../../../helpers'

import MenuItem from './Menu/Item'

const Navbar = () => {
  const { header } = useAdminContext()

  return (
    <div
      className="app-header-menu app-header-mobile-drawer align-items-stretch"
      style={header?.center && { flexBasis: '1px' }}
      data-ttz-drawer="true"
      data-ttz-drawer-name="app-header-menu"
      data-ttz-drawer-activate="{default: true, lg: false}"
      data-ttz-drawer-overlay="true"
      data-ttz-drawer-width="250px"
      data-ttz-drawer-direction="end"
      data-ttz-drawer-toggle="#ttz_app_header_menu_toggle"
      data-ttz-swapper="true"
      data-ttz-swapper-mode="{default: 'append', lg: 'prepend'}"
      data-ttz-swapper-parent="{default: '#ttz_app_body', lg: '#ttz_app_header_wrapper'}"
    >
      <div
        id="#ttz_app_header_menu"
        className="menu menu-rounded menu-column  menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
        data-ttz-menu="true"
      >
        {header.navbarMenuItems &&
          filterMenuItemsByCondition(header.navbarMenuItems).map(item => (
            <MenuItem key={item.slug} level={1} {...item} />
          ))}
      </div>
    </div>
  )
}

export default Navbar
