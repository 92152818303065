import _ from 'lodash'

import {
  PIX,
  BANK_SLIP,
  CREDIT_CARD,
  BANK_SLIP_IN_INSTALLMENTS,
  IUGU_PIX_TAX_CENTS,
  IUGU_PIX_TAX_PERCENT,
  TTZPAY_PIX_TAX_CENTS,
  TTZPAY_PIX_TAX_PERCENT,
  IUGU_BANK_SLIP_TAX_CENTS,
  IUGU_BANK_SLIP_TAX_PERCENT,
  TTZPAY_BANK_SLIP_TAX_CENTS,
  TTZPAY_BANK_SLIP_TAX_PERCENT,
  IUGU_CREDIT_CARD_TAX_PERCENT,
  IUGU_CREDIT_CARD_TAX_CENTS,
  TTZPAY_CREDIT_CARD_TAX_PERCENT,
  TTZPAY_CREDIT_CARD_TAX_CENTS,
  IUGU_BANK_SLIP_IN_INSTALLMENTS_TAX_PERCENT,
  IUGU_BANK_SLIP_IN_INSTALLMENTS_TAX_CENTS,
  TTZPAY_BANK_SLIP_IN_INSTALLMENTS_TAX_PERCENT,
  TTZPAY_BANK_SLIP_IN_INSTALLMENTS_TAX_CENTS,
  DEFAULT_MODE_TAX_CENTS,
  DEFAULT_MODE_TAX_PERCENT,
  DEFAULT_MODE_FEE_PERCENT
} from './config'
import _calcTax from './utils/_calcTax'

const getManualModeTaxes = netPriceCents => {
  const pix = _calcTax(
    netPriceCents,
    [
      { value: TTZPAY_PIX_TAX_CENTS, type: 'cents' },
      { value: TTZPAY_PIX_TAX_PERCENT, type: 'percent' }
    ],
    [
      { value: IUGU_PIX_TAX_CENTS, type: 'cents' },
      { value: IUGU_PIX_TAX_PERCENT, type: 'percent' }
    ]
  )
  const bankSlip = _calcTax(
    netPriceCents,
    [
      { value: TTZPAY_BANK_SLIP_TAX_CENTS, type: 'cents' },
      { value: TTZPAY_BANK_SLIP_TAX_PERCENT, type: 'percent' }
    ],
    [
      { value: IUGU_BANK_SLIP_TAX_CENTS, type: 'cents' },
      { value: IUGU_BANK_SLIP_TAX_PERCENT, type: 'percent' }
    ]
  )

  const taxes = {
    [PIX]: pix,
    [BANK_SLIP]: bankSlip,
    [CREDIT_CARD]: {},
    [BANK_SLIP_IN_INSTALLMENTS]: {}
  }

  _.range(1, 13).forEach(installment => {
    taxes[CREDIT_CARD][installment] = _calcTax(
      netPriceCents,
      [
        { value: TTZPAY_CREDIT_CARD_TAX_CENTS, type: 'cents' },
        { value: TTZPAY_CREDIT_CARD_TAX_PERCENT(installment), type: 'percent' }
      ],
      [
        { value: IUGU_CREDIT_CARD_TAX_CENTS, type: 'cents' },
        { value: IUGU_CREDIT_CARD_TAX_PERCENT(installment), type: 'percent' }
      ]
    )
    if (installment > 1) {
      taxes[BANK_SLIP_IN_INSTALLMENTS][installment] = _calcTax(
        netPriceCents,
        [
          {
            value: TTZPAY_BANK_SLIP_IN_INSTALLMENTS_TAX_CENTS(installment),
            type: 'cents'
          },
          {
            value: TTZPAY_BANK_SLIP_IN_INSTALLMENTS_TAX_PERCENT(installment),
            type: 'percent'
          }
        ],
        [
          {
            value: IUGU_BANK_SLIP_IN_INSTALLMENTS_TAX_CENTS(installment),
            type: 'cents'
          },
          {
            value: IUGU_BANK_SLIP_IN_INSTALLMENTS_TAX_PERCENT,
            type: 'percent'
          }
        ]
      )
    }
  })

  return taxes
}

const getDefaultModeTaxes = netPriceCents => {
  const taxesInInstallments = _calcTax(netPriceCents, [
    { value: DEFAULT_MODE_TAX_CENTS, type: 'cents' },
    { value: DEFAULT_MODE_TAX_PERCENT, type: 'percent' }
  ])
  const taxesInCash = Math.round(
    (netPriceCents + taxesInInstallments) * (1 - DEFAULT_MODE_FEE_PERCENT) -
      netPriceCents
  )

  const taxes = {
    [PIX]: taxesInCash,
    [BANK_SLIP]: taxesInCash,
    [CREDIT_CARD]: {},
    [BANK_SLIP_IN_INSTALLMENTS]: {}
  }

  _.range(1, 13).forEach(installment => {
    taxes[CREDIT_CARD][installment] = taxesInInstallments

    if (installment > 1) {
      taxes[BANK_SLIP_IN_INSTALLMENTS][installment] = taxesInInstallments
    }
  })

  return taxes
}

const getTaxes = (sellingMode, netTaxCents) => {
  const [isDefaultMode, isManualMode] = [
    sellingMode == 'default',
    sellingMode == 'manual'
  ]

  if (isManualMode) return getManualModeTaxes(netTaxCents)
  else return getDefaultModeTaxes(netTaxCents)
}

export default getTaxes
export { getManualModeTaxes, getDefaultModeTaxes }
