import React from 'react'

const ScrollTop = () => {
  return (
    <div id="ttz_scrolltop" className="scrolltop" data-ttz-scrolltop="true">
      <span className="svg-icon">
        <i className="fad fa-arrow-up text-white" />
      </span>
    </div>
  )
}

export default ScrollTop
