import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  forwardRef
} from 'react'

import { useRouter } from 'next/router'
import { useForm, FormProvider } from 'react-hook-form'
import _ from 'lodash'
import { yupResolver } from '@hookform/resolvers/yup'

import { useResourceRecordContext } from '../../context/ResourceRecordContext'

const ResourceRecordFormWizardStep = ({
  defaultValues = {},
  useFormConfig = {},
  onSubmitStep,
  children,
  ...rest
}) => {
  const Form = ({ validationSchema, className = '', children }) => {
    const formRef = useRef()
    const { record } = useResourceRecordContext()
    const methods = useForm({
      resolver: validationSchema ? yupResolver(validationSchema) : null,
      context: record,
      defaultValues: record ? record : defaultValues,
      // shouldUseNativeValidation: true,
      ...useFormConfig
    })

    const submitForm = () => {
      // This is not working, so we made some workarounds
      // filtersFormRef.current.dispatchEvent(
      //   new Event("submit", { cancelable: true })
      // );
      formRef.current.querySelector('input[type=submit]').click()
    }

    return (
      <FormProvider submitForm={submitForm} {...methods}>
        <form
          ref={formRef}
          onSubmit={methods.handleSubmit(onSubmitStep)}
          className={`h-100 d-flex flex-column ${className}`}
        >
          {children}
          <input type="submit" hidden />
        </form>
      </FormProvider>
    )
  }

  return React.cloneElement(children, { Form, ...rest })
}

export default ResourceRecordFormWizardStep
