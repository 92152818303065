import _ from 'lodash'

const objKeysToSnakeCase = obj => {
  if (Array.isArray(obj)) {
    return obj.map(v => objKeysToSnakeCase(v))
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [_.snakeCase(key)]: objKeysToSnakeCase(obj[key])
      }),
      {}
    )
  }
  return obj
}

export default objKeysToSnakeCase
