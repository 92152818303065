const SidebarMobileToggle = () => (
  <div
    className="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2"
    title="Mostrar menu lateral"
  >
    <div
      id="ttz_app_sidebar_mobile_toggle"
      className="btn btn-icon btn-active-color-primary w-35px h-35px"
    >
      <i className="fad fa-bars fs-1" />
    </div>
  </div>
)

export default SidebarMobileToggle
