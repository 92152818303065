import React, { useEffect, useState, useRef } from 'react'

import StepWizard from 'react-step-wizard'

import Card from './Card'

const StepNavItem = ({ number, icon, title, desc, currentStep }) => {
  const getStatus = () => {
    if (number < currentStep) return 'completed'
    if (number == currentStep) return 'current'
    return 'pending'
  }

  return (
    <div className={`stepper-item ${getStatus()}`}>
      <div className="stepper-line w-40px"></div>

      <div className="stepper-icon w-40px h-40px">
        <i className={'stepper-check fas fa-check'}></i>
        <span className="stepper-number">{number}</span>
      </div>

      <div className="stepper-label">
        <h3 className="stepper-title">{title}</h3>
        {desc && <div className="stepper-desc">{desc}</div>}
      </div>
    </div>
  )
}

const Step = ({ content, steps, ...rest }) => {
  const StepContent = content

  return (
    <div className="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid between">
      <div className="d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9">
        <div className="stepper-nav">
          {steps.map((step, index) => (
            <StepNavItem
              key={`step-${step.slug}`}
              number={index + 1}
              {...rest}
              {...step}
            />
          ))}
        </div>
      </div>
      <div className="flex-row-fluid py-20 px-6 px-lg-10 px-xxl-15">
        <StepContent {...rest} />
      </div>
    </div>
  )
}

let custom = {
  enterRight: '',
  enterLeft: '',
  exitRight: '',
  exitLeft: ''
}

const Steps = ({ steps }) => {
  const stepWizardRef = useRef(null)

  return (
    <StepWizard ref={stepWizardRef} isHashEnabled={true} transitions={custom}>
      {steps.map(step => (
        <Step key={step.slug} steps={steps} {...step} />
      ))}
    </StepWizard>
  )
}

export default Steps
