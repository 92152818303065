const Alert = ({
  title,
  subtitle,
  icon = 'fad fa-square-exclamation',
  variant = 'primary',
  className = '',
  children
}) => {
  return (
    <div
      className={`alert alert-${variant} d-flex align-items-center p-5 ${className}`}
    >
      <i className={`${icon} text-${variant} me-4 fs-2hx`} />
      <div className="d-flex flex-column">
        {title && <h4 className={`mb-1 text-${variant}`}>{title}</h4>}
        {subtitle && <span>{subtitle}</span>}
        {children}
      </div>
    </div>
  )
}

export default Alert
