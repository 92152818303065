import React from 'react'

import Link from 'next/link'

const ItemLink = ({ name, title, href, path, target = '_self' }) => {
  const pathOrHref = path || href

  if (!pathOrHref) return <>{title}</>

  return (
    <Link
      href={pathOrHref}
      className="text-muted text-hover-primary"
      target={target}
    >
      {name || title}
    </Link>
  )
}

const Item = ({ last, ...rest }) => {
  return (
    <>
      <li className={`breadcrumb-item ${last ? 'text-dark' : 'text-muted'}`}>
        <ItemLink {...rest} />
      </li>
      {!last && (
        <li className="breadcrumb-item">
          <span className="bullet bg-gray-400 w-5px h-2px" />
        </li>
      )}
    </>
  )
}

const ToolbarBreadcrumbs = ({ items, className }) => {
  return (
    <ul
      className={`breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 ${className}`}
    >
      {items.map((item, index) => (
        <Item key={item.slug} last={index + 1 === items.length} {...item} />
      ))}
    </ul>
  )
}

export default ToolbarBreadcrumbs
