import Swal from 'sweetalert2'
import _ from 'lodash'
import store from 'store2'
import toastr from 'toastr'

import getErrorMessage from '../../services/getErrorMessage'
import showErrorAlert from '../../services/showErrorAlert'

import manageNestedFields from './manageNestedFields'
import manageReferenceFields from './manageReferenceFields'

const handleSubmit = async ({
  apiBasePath,
  beforeSave = async data => data,
  data,
  record: oldRecord,
  dataProvider,
  id,
  isEdit,
  customAction,
  methods,
  refetch,
  onError,
  onSave,
  onSubmit,
  resetOnSave,
  router,
  setIsSubmitting,
  setSavedRecord
}) => {
  const handleCreate = data => dataProvider.create(apiBasePath, data)
  const handleUpdate = data => dataProvider.update(apiBasePath, id, data)
  const handleCustomAction = data =>
    dataProvider.customAction(
      apiBasePath,
      id,
      _.get(customAction, 'name', customAction),
      customAction?.method,
      data
    )
  const setErrors = (data, errors) => {
    if (_.isObject(errors)) {
      for (var [key, value] of Object.entries(errors)) {
        if (_.has(data, key)) {
          try {
            methods.setError(key, {
              type: 'manual',
              message: value.join(', ')
            })
          } catch (e) {}
        }
      }
    }
  }

  const handleErrors = (errors, errorMessage) => {
    if (
      (_.isObject(errors) ||
        (_.has(errors, 'response.data.errors') &&
          _.isArray(_.get(errors, 'response.data.errors')))) &&
      methods
    )
      setErrors(data, _.get(errors, 'response.data.errors', errors))

    if (onError) onError(errors)
    else showErrorAlert(errorMessage || errors)
  }

  let actionHandler = isEdit ? handleUpdate : handleCreate
  if (onSubmit) actionHandler = onSubmit
  if (customAction) actionHandler = handleCustomAction

  setIsSubmitting(true)

  try {
    data = await beforeSave(data)

    data = await manageNestedFields(data, isEdit ? oldRecord : {})
    data = await manageReferenceFields(data)

    const saveRes = await actionHandler(data)
    const record = _.get(saveRes, 'data.record', null)
    const newRecord = { ...data, ...(record ? record : {}) }
    const errors = _.get(saveRes, 'data.errors', null)
    const errorMessage = _.get(saveRes, 'data.error_message', null)

    setSavedRecord(newRecord)
    try {
      isEdit && refetch && refetch(newRecord)
    } catch (error) {}

    if (errors || errorMessage) {
      handleErrors(errors, errorMessage)
    } else {
      store('NEXT_LOCALE') == 'en'
        ? toastr.success('Record successfully saved', 'Done!')
        : toastr.success('Registro salvo com sucesso', 'Pronto!')

      if (resetOnSave) methods.reset()
      if (onSave) onSave(newRecord, () => router.back())
      else router.back()
    }
  } catch (error) {
    handleErrors(error)
  }

  setIsSubmitting(false)
}

export default handleSubmit
