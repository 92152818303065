import _ from 'lodash'
import * as Sentry from '@sentry/nextjs'

import CustomerError from '../../../services/customErrors/CustomerError'
import { iuguApi } from '../api'

/**
 * Criar fatura
 *
 * https://dev.iugu.com/reference/criar-fatura
 */
const createIuguCustomer = async ({
  apiToken,
  email,
  name,
  notes,
  phone,
  phone_prefix,
  cpf_cnpj,
  zip_code,
  number,
  street,
  city,
  state,
  district,
  complement
}) => {
  let data = {
    email,
    name,
    notes,
    phone,
    phone_prefix,
    cpf_cnpj,
    zip_code,
    number,
    street,
    city,
    state,
    district,
    complement
  }

  try {
    const createIuguCustomerRes = await iuguApi.post(
      `/customers?api_token=${apiToken}`,
      data
    )
    const customer = { ...createIuguCustomerRes.data }

    return customer
  } catch (error) {
    if (process.env.NODE_ENV == 'development')
      console.log('IUGU_CREATE_INVOICE', error)

    const errorObject = _.has(error, 'response.data.errors')
      ? new CustomerError(_.get(error, 'response.data.errors'))
      : new Error(error)

    Sentry.captureException(errorObject)

    throw errorObject
  }
}

export default createIuguCustomer
