import _ from 'lodash'
import qs from 'qs'
import { useInfiniteQuery } from '@tanstack/react-query'
import { v4 as uuidv4 } from 'uuid'

import { useDataProviderContext } from '@tootz/react-admin'

import getQuery from '../services/getQuery'
import objKeysToSnakeCase from '../utils/objKeysToSnakeCase'

const useRecordsInfinite = (
  resource,
  queryParams = {},
  options = {
    refetchInterval: 60000,
    refetchIntervalInBackground: 180000,
    retry: false,
    retryDelay: attempt =>
      Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000)
  },
  shouldFetch = true
) => {
  let r = resource

  if (_.isObject(resource)) {
    r = resource.r || resource.resource
    options = resource.options || {
      refetchInterval: 60000,
      refetchIntervalInBackground: 180000,
      retry: false,
      retryDelay: attempt =>
        Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000)
    }
    queryParams = resource.queryParams || {}
    shouldFetch =
      typeof resource.shouldFetch == 'undefined' || resource.shouldFetch
  } else {
    if (typeof shouldFetch == 'undefined') shouldFetch = true
  }

  const { httpClient } = useDataProviderContext()
  const { pagination, sort, filter, params } = objKeysToSnakeCase(queryParams)

  const getListKey = async ({ pageParam = 1 }) => {
    const query = getQuery({
      pagination: {
        ...pagination,
        page: pageParam
      },
      sort,
      filter: _.pickBy(filter)
    })
    const path = r
      ? `${r}?${qs.stringify(query, { arrayFormat: 'brackets' })}`
      : null
    const res = await httpClient.get(path)

    return res.data
  }

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch,
    ...rest
  } = useInfiniteQuery({
    queryKey: [r, pagination, sort, filter, params],
    queryFn: getListKey,
    getNextPageParam: ({ pagination }, allPages) => {
      return pagination.page < pagination.page_count && pagination.page + 1
    }
  })

  const records = data
    ? data.pages.reduce((all, curr) => all.concat(curr.records), [])
    : []

  const isLoading = status === 'loading'
  const isError = status === 'error'
  const isSuccess = !isLoading && !isError
  const isEmpty = !isLoading && !isError && records.length === 0

  // Deprecard
  const isReachingEnd = !isLoading && !hasNextPage

  return {
    records,
    pagination: _.get(data, `[${data?.length - 1}].pagination`, null),
    status,
    isLoading,
    isSuccess,
    isError,
    isEmpty,
    isReachingEnd,
    isFetching,
    isFetchingNextPage,
    error,
    hasNextPage,
    fetchNextPage,
    refetch,
    mutate: refetch, // Mutate is deprecated, use refetch instead
    ...rest,

    size: isFetchingNextPage ? 2 : 1, // Deprecated, use isFetchingNextPage
    setSize: () => fetchNextPage() // Deprecated, use fetchNextPage
  }
}

export default useRecordsInfinite
export { getQuery }
