import Link from 'next/link'

import { useAdminContext } from '../context/AdminContext'

const Footer = () => {
  const { footer, containerFluid } = useAdminContext()

  if (!footer || footer.disabled) return <></>

  return (
    <div id="ttz_app_footer" className="app-footer ">
      <div
        className={`app-container container${
          containerFluid ? '-fluid' : ''
        } d-flex flex-column flex-md-row flex-center flex-md-stack py-3`}
      >
        {footer.copyright && (
          <div className="text-dark order-2 order-md-1">{footer.copyright}</div>
        )}
        {footer.menuItems && (
          <ul className="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
            {footer.menuItems.map(
              ({ slug, href, path, target = '_self', title }) => (
                <li key={href || path} className="menu-item">
                  <Link
                    href={slug || href || path}
                    target={target}
                    className="menu-link px-2"
                    rel="noreferrer"
                  >
                    {title}
                  </Link>
                </li>
              )
            )}
          </ul>
        )}
      </div>
    </div>
  )
}

export default Footer
