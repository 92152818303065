import _ from 'lodash'

/**
 * Calculates the taxes given a conjunction of splits and gateway taxes,
 * each one with a ordered cents and percents values to be added in sequence
 * to the net price cents
 *
 * @param {number} netPriceCents
 * @param {Array<{value: Number, type: string}>} splitTaxes
 *    Array of taxes objects with value and type, that could be cents or percent
 * @param {Array<{value: Number, type: string}>} gatewayTaxes
 *    Array of taxes objects with value and type, that could be cents or percent
 * @returns {number} Returns the tax for the value.
 */
const _calcTax = (netPriceCents, splitTaxes = [], gatewayTaxes = []) => {
  // console.log('_calcTax', netPriceCents, splitTaxes, gatewayTaxes)

  let priceCents = netPriceCents
  const taxes = splitTaxes.concat(gatewayTaxes)

  for (let i = 0; i < taxes.length; i++) {
    const tax = taxes[i]

    if (tax.type == 'percent') {
      priceCents +=
        Math.floor((priceCents * 100) / (100 - tax.value * 100)) - priceCents
    } else {
      priceCents += tax.value
    }
  }

  const tax = priceCents - netPriceCents

  // console.log('_calcTax: return', tax)

  return tax
}

export default _calcTax
