import _ from 'lodash'

import { removeQuestionMarkFromObjKeys } from '../../../utils'
import getIuguCustomer from '../gateways/iugu/customers/get'
import getErrorResponse from '../services/getErrorResponse'


const getCustomer = async ({
  account,
  id
}) => {

  const apiToken =
    account[process.env.NODE_ENV == 'development' ? 'test_token' : 'live_token']

  const data = {
    apiToken,
    id
  }

  try {
    const customer = await getIuguCustomer(data)

    return {
      status: 200,
      body: {
        record: removeQuestionMarkFromObjKeys(customer)
      }
    }
  } catch (error) {
    const status = _.get(error, 'response.status', 500)

    return getErrorResponse('CREATE_CUSTOMER', status, error)
  }
}

export default getCustomer
