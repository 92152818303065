import * as Sentry from '@sentry/nextjs'
import _ from 'lodash'

import PaymentMethodError from '../../../services/customErrors/PaymentMethodError'
import { iuguApi } from '../api'

/**
 * Criar fatura
 *
 * https://dev.iugu.com/reference/criar-fatura
 */
const createIuguPaymentMethod = async ({
  apiToken,
  customer_id,
  description,
  token,
}) => {
  let data = {
    description,
    token,
    set_as_default: true
  }

  try {
    const createIuguCustomerRes = await iuguApi.post(
      `/customers/${customer_id}/payment_methods?api_token=${apiToken}`,
      data
    )
    const customer = { ...createIuguCustomerRes.data }

    return customer
  } catch (error) {
    if (process.env.NODE_ENV == 'development')
      console.log('IUGU_CREATE_INVOICE', error)

    const errorObject = _.has(error, 'response.data.errors')
      ? new PaymentMethodError(_.get(error, 'response.data.errors'))
      : new Error(error)

    Sentry.captureException(errorObject)

    throw errorObject
  }
}

export default createIuguPaymentMethod
