import React, { createContext, useContext, useState } from 'react'

import _ from 'lodash'
import Swal from 'sweetalert2'

import { useDataProviderContext } from '../context/DataProviderContext'
import ResourceRecordForm from '../domain/ResourceRecordForm'
import ResourceRecordFormWizard from '../domain/ResourceRecordFormWizard'
import { useRecord } from '../hooks/useRecord'

const ResourceRecordContext = createContext()

const useResourceRecordContext = () => useContext(ResourceRecordContext)

const ResourceRecordProvider = ({
  apiBasePath,
  id,
  edit,
  options,
  forceIdPresence = true,
  shouldFetch = true,
  query,
  customAction,
  children,
  ...rest
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [savedRecord, setSavedRecord] = useState(null)
  const { dataProvider } = useDataProviderContext()
  const isEdit = edit || !!id || !forceIdPresence
  const {
    record: loadedRecord,
    errors,
    isLoading,
    isError,
    isSuccess,
    loadWrapperConfig,
    isEmpty,
    mutate,
    ...recordRest
  } = useRecord({
    r: apiBasePath,
    id,
    options: {
      revalidateOnFocus: false,
      ...options
    },
    forceIdPresence,
    shouldFetch,
    query,
    action: customAction
  })
  const record = savedRecord ? savedRecord : loadedRecord

  const handleDelete = async (props, config, path = apiBasePath, callback) => {
    let status
    let id = props
    if (_.isObject(props)) {
      id = props.id
      config = props.config
      path = props.path || apiBasePath
      callback = props.callback
    }

    if (!path)
      throw new Error(
        `No apiBasePath provided for delete action with id '${id}'`
      )

    let mustConfirm = true
    let title = 'Tem certeza?'
    let text = 'O registro será excluído permanentemente!'

    if (config) {
      const { confirm, confirmTitle, confirmText } = config

      if (confirm === false) mustConfirm = false
      if (confirmTitle) title = confirmTitle
      if (confirmText) text = confirmText
    }

    const deleteRecord = async id => {
      try {
        const deleteRes = await dataProvider.delete(path, id)

        Swal.fire({
          title: 'Excluído!',
          text: 'Seu registro foi excluído permanentemente.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-light btn-active-light-primary'
          }
        })

        status = 'deleted'
        callback && callback(true, _.get(deleteRes, 'data'))
      } catch (error) {
        showErrorAlert(error)

        status = 'error'
        callback && callback(false, error)
      }
    }

    if (mustConfirm) {
      try {
        const confirmSwalRes = await Swal.fire({
          title,
          text,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, pode excluir!',
          cancelButtonText: 'Não',
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-light btn-active-light-primary'
          }
        })
        const { isConfirmed, dismiss } = confirmSwalRes

        if (isConfirmed) {
          await deleteRecord(id)
        } else {
          status = 'canceled'

          if (dismiss == 'cancel')
            Swal.fire('Cancelado', 'Seu registro está a salvo', 'error')
        }
      } catch (error) {}
    } else {
      await deleteRecord(id)
    }

    return { status }
  }

  const contextProps = {
    apiBasePath,
    id,
    record: savedRecord ? savedRecord : record,
    errors,
    isLoading,
    isError,
    isSuccess,
    isEmpty,
    loadWrapperConfig,
    isSubmitting,
    isEdit,
    isNew: !isEdit,
    customAction,
    dataProvider,
    ...recordRest,
    ...rest
  }
  const contextMethods = {
    handleDelete,
    setIsSubmitting,
    setSavedRecord,
    mutate
  }
  const contextComponents = {
    Form: ResourceRecordForm,
    FormWizard: ResourceRecordFormWizard
  }

  return (
    <ResourceRecordContext.Provider
      value={{
        ...contextProps,
        ...contextMethods,
        ...contextComponents
      }}
    >
      {children}
    </ResourceRecordContext.Provider>
  )
}

// eslint-disable-next-line react/display-name
const withResourceRecord = (Component, propConfig) => componentProps => {
  const config = _.isFunction(propConfig)
    ? propConfig(componentProps)
    : propConfig

  return (
    <ResourceRecordProvider {...componentProps} {...config} config={config}>
      <Component config={config} {...componentProps} />
    </ResourceRecordProvider>
  )
}

export { useResourceRecordContext, ResourceRecordProvider, withResourceRecord }
