import * as Sentry from '@sentry/nextjs'
import _ from 'lodash'

import CustomerError from '../../../services/customErrors/CustomerError'
import { iuguApi } from '../api'

/**
 * Criar fatura
 *
 * https://dev.iugu.com/reference/criar-fatura
 */
const getIuguCustomer = async ({
  apiToken,
  id
}) => {
  

  try {
    const getIuguCustomerRes = await iuguApi.get(
      `/customers/${id}?api_token=${apiToken}`,
      data
    )
    const customer = { ...getIuguCustomerRes.data }

    return customer
  } catch (error) {
    if (process.env.NODE_ENV == 'development')
      console.log('IUGU_CREATE_INVOICE', error)

    const errorObject = _.has(error, 'response.data.errors')
      ? new CustomerError(_.get(error, 'response.data.errors'))
      : new Error(error)

    Sentry.captureException(errorObject)

    throw errorObject
  }
}

export default getIuguCustomer
