import { useAdminContext } from '../../context/AdminContext'

import SidebarFooter from './Footer'
import SidebarLogo from './Logo'
import SidebarMenu from './Menu'

const Sidebar = () => {
  const { sidebar } = useAdminContext()

  return (
    <div
      id="ttz_app_sidebar"
      className="app-sidebar flex-column"
      data-ttz-drawer="true"
      data-ttz-drawer-name="app-sidebar"
      data-ttz-drawer-activate="{default: true, lg: false}"
      data-ttz-drawer-overlay="true"
      data-ttz-drawer-width="225px"
      data-ttz-drawer-direction="start"
      data-ttz-drawer-toggle="#ttz_app_sidebar_mobile_toggle"
    >
      {!sidebar?.disableLogo && <SidebarLogo />}
      {sidebar?.preMenu}
      <SidebarMenu />
      {sidebar?.postMenu}
      <SidebarFooter />
    </div>
  )
}

export default Sidebar
