import React, { useEffect, useState } from 'react'

const Notice = ({
  bg = 'light-primary',
  border = 'primary',
  className = '',
  variant,
  children,
  ...rest
}) => {
  const [_bg, setBg] = useState(bg)
  const [_border, setBorder] = useState(border)

  useEffect(() => {
    if (variant) {
      setBg(`light-${variant}`)
      setBorder(variant)
    }
  }, [variant])

  return (
    <div
      className={`notice d-flex bg-${_bg} rounded border-${_border} border border-dashed p-6 ${className}`}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Notice
