import React, { useEffect } from 'react'

import { useRouter } from 'next/router'
import { signIn } from 'next-auth/react'
import ReactLoading from 'react-loading'

import { useSession } from '@tootz/react-admin'

import MyApp from './App'

const GuardContainer = ({ children }) => {
  const router = useRouter()
  const { data: session, status } = useSession()
  const loading = status === 'loading'

  const redirectToSignIn = () => {
    signIn()
  }

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center vh-100 vw-100">
        <ReactLoading type="bubbles" color="#3F4254" height={50} width={50} />
      </div>
    )

  if (router.pathname.startsWith('/p/')) return <>{children}</>
  if (router.pathname.startsWith('/auth/')) return <>{children}</>

  if (!loading && !session) return <>{redirectToSignIn()}</>

  return <MyApp>{children}</MyApp>
}

export default GuardContainer
