import { filterMenuItemsByCondition } from '../../../helpers'

import SidebarMenuItem from './Item'

const SidebarMenuItemSubAccordion = ({ items }) => (
  <div className="menu-sub menu-sub-accordion menu-active-bg">
    {filterMenuItemsByCondition(items).map(({ slug, ...rest }) => (
      <SidebarMenuItem key={slug} {...rest} />
    ))}
  </div>
)

export default SidebarMenuItemSubAccordion
