import classNames from 'classnames'

import { useAdminContext } from '../../context/AdminContext'

import ToolbarBreadcrumbs from './Breadcrumbs'

const Toolbar = ({ title, breadcrumbs, vertical, children }) => {
  const { containerFluid, toolbar } = useAdminContext()
  const horizontal = !(vertical || toolbar.vertical)
  const hasTitleOrBreadcbrums = title || breadcrumbs
  const containerClasses = classNames('d-flex flex-stack w-100', {
    'app-container': false,
    'container-fluid': false,
    container: false
  })
  const pageTitleClasses = classNames(
    'page-title d-flex justify-content-center flex-wrap me-3 ',
    {
      'flex-column': !horizontal
    }
  )

  return (
    <div id="ttz_app_toolbar" className="app-toolbar py-3 py-lg-6">
      <div id="ttz_app_toolbar_container" className={containerClasses}>
        {hasTitleOrBreadcbrums ? (
          <>
            <div className={pageTitleClasses}>
              <h1 className="page-heading d-flex text-dark fw-bold fs-3 align-items-center my-0">
                {title}
              </h1>
              {breadcrumbs && breadcrumbs.length && (
                <>
                  {horizontal && (
                    <span className="h-20px border-gray-300 border-start mx-4"></span>
                  )}
                  <ToolbarBreadcrumbs
                    items={breadcrumbs}
                    className={horizontal ? '' : 'pt-1'}
                  />
                </>
              )}
            </div>
            <div className="d-flex align-items-center overflow-auto text-nowrap">
              {children}
            </div>
          </>
        ) : (
          children
        )}
      </div>
    </div>
  )
}

export default Toolbar
