import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef
} from 'react'

import _ from 'lodash'
import { useForm, FormProvider } from 'react-hook-form'
import Swal from 'sweetalert2'

import DefaultPagination from '../components/Pagination'
import { useDataProviderContext } from '../context/DataProviderContext'
import useRecords from '../hooks/useRecords'
import useRecordsInfinite from '../hooks/useRecordsInfinite'
import showErrorAlert from '../services/showErrorAlert'

const ResourceRecordsContext = createContext({})

const useResourceRecordsContext = () => useContext(ResourceRecordsContext)

const FiltersForm = ({
  beforeFilter,
  defaultValues,
  onSubmit,
  onReset,
  className = '',
  formAttrsDecorator,
  children
}) => {
  const { params, setParamsAttrs, filtersFormRef } = useResourceRecordsContext()
  const methods = useForm({
    defaultValues: defaultValues ? defaultValues : params.filter,
    shouldUnregister: true,
    shouldIncludeDefaults: true
  })
  const handleSubmit = async data => {
    let newFilter = { ...params.filter, ...data }

    if (beforeFilter && _.isFunction(beforeFilter))
      newFilter = beforeFilter(newFilter)

    setParamsAttrs('filter', newFilter, 'pagination.page', 1)

    if (onSubmit && _.isFunction(onSubmit)) onSubmit(newFilter)
  }
  const handleReset = () => {
    onReset && onReset()
    methods.reset({})
    setParamsAttrs('filter', {}, 'pagination.page', 1)
  }

  useEffect(() => {
    let newFilter = { ...(params?.filter || {}) }

    if (formAttrsDecorator && _.isFunction(formAttrsDecorator))
      newFilter = formAttrsDecorator(newFilter)

    methods.reset(newFilter)
  }, [params?.filter])

  return (
    <FormProvider {...methods}>
      <form
        ref={filtersFormRef}
        onReset={handleReset}
        onSubmit={methods.handleSubmit(handleSubmit)}
        className={className}
      >
        {children}
        <input type="submit" hidden />
      </form>
    </FormProvider>
  )
}

const ResourceRecordsProvider = ({
  apiBasePath,
  pagination: propsPagination,
  sort = {},
  filter = {},
  customParams = {},
  infinite,
  useRecordsConfig = {
    options: {},
    resultsDecorator: null
  },
  CustomPagination,
  children,
  ...rest
}) => {
  const [params, setParams] = useState({
    pagination: {
      page: 1,
      perPage: 12,
      pageCount: null,
      totalCount: null,
      ...propsPagination
    },
    sort: _.isArray(sort)
      ? sort
      : {
          field: 'created_at',
          order: 'desc',
          ...sort
        },
    filter,
    params: customParams
  })
  const filtersFormRef = useRef()
  const { dataProvider } = useDataProviderContext()
  const [deletedRecords, setDeletedRecords] = useState([])
  const useRecordsFn = infinite ? useRecordsInfinite : useRecords
  const {
    records,
    pagination = {},
    isLoading,
    isError,
    isSuccess,
    isEmpty,
    isReachingEnd,
    mutate,
    refetch,
    size,
    setSize,
    ...recordsRest
  } = useRecordsFn(
    apiBasePath,
    params,
    useRecordsConfig.options,
    useRecordsConfig?.shouldFetch !== null &&
      typeof useRecordsConfig?.shouldFetch == 'undefined'
      ? useRecordsConfig?.shouldFetch
      : true
  )
  const recordsWithoutDeleted =
    records && _.isArray(records)
      ? records.filter(
          r =>
            !deletedRecords.includes(r.id) && !deletedRecords.includes(r.slug)
        )
      : null
  const setParamsAttrs = (...args) => {
    let paramsCopy = _.cloneDeep(params)

    for (let i = 0; i < args.length / 2; i++)
      paramsCopy = _.set(paramsCopy, args[i * 2], args[i * 2 + 1])

    setParams(paramsCopy)
  }
  const setFilter = value =>
    setParams(prevState => ({
      ...prevState,
      filter: _.isFunction(value) ? value(prevState.filter) : value
    }))
  const resetFilters = () => setFilter({})
  const setSort = value =>
    setParams(prevState => ({
      ...prevState,
      sort: {
        field: 'created_at',
        order: 'desc',
        ...(_.isFunction(value) ? value(prevState.sort) : value)
      }
    }))
  const handleGoToPage = value => setParamsAttrs('pagination.page', value)
  const handleSort = (field, order) =>
    setParamsAttrs('sort.field', field, 'sort.order', order)
  const handleSelectPerPage = value =>
    setParamsAttrs('pagination.perPage', value)
  const handleDelete = async (props, config, path = apiBasePath, callback) => {
    let status
    let id = props
    if (_.isObject(props)) {
      id = props.id
      config = props.config
      path = props.path || apiBasePath
      callback = props.callback
    }

    if (!path)
      throw new Error(
        `No apiBasePath provided for delete action with id '${id}'`
      )

    let mustConfirm = true
    let title = 'Tem certeza?'
    let text = 'O registro será excluído permanentemente!'

    if (config) {
      const { confirm, confirmTitle, confirmText } = config

      if (confirm === false) mustConfirm = false
      if (confirmTitle) title = confirmTitle
      if (confirmText) text = confirmText
    }

    const deleteRecord = async id => {
      try {
        const deleteRes = await dataProvider.delete(path, id)

        Swal.fire({
          title: 'Excluído!',
          text: 'Seu registro foi excluído permanentemente.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-light btn-active-light-primary'
          }
        })

        status = 'deleted'
        callback && callback(true, _.get(deleteRes, 'data'))
        setDeletedRecords(prevState => [...prevState, id])
      } catch (error) {
        showErrorAlert(error)

        status = 'error'
        callback && callback(false, error)
      }
    }

    if (mustConfirm) {
      try {
        const confirmSwalRes = await Swal.fire({
          title,
          html: text,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim, pode excluir!',
          cancelButtonText: 'Não',
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-light btn-active-light-primary'
          }
        })
        const { isConfirmed, dismiss } = confirmSwalRes

        if (isConfirmed) {
          await deleteRecord(id)
        } else {
          status = 'canceled'

          if (dismiss == 'cancel')
            Swal.fire('Cancelado', 'Seu registro está a salvo', 'error')
        }
      } catch (error) {}
    } else {
      await deleteRecord(id)
    }

    return { status }
  }
  const isSorting = name => params.sort?.field == name
  const submitFiltersForm = () => {
    // This is not working, so we made some workarounds
    // filtersFormRef.current.dispatchEvent(
    //   new Event("submit", { cancelable: true })
    // );
    filtersFormRef.current.querySelector('input[type=submit]').click()
  }

  const Pagination = () => {
    const Component = CustomPagination ? CustomPagination : DefaultPagination

    return (
      <Component
        page={pagination.page}
        perPage={
          pagination.per_page > pagination.total_count
            ? pagination.total_count
            : pagination.per_page
        }
        pageCount={pagination.page_count}
        totalCount={pagination.total_count}
        isLoading={isLoading}
        goToPage={handleGoToPage}
        onSelectPerPage={handleSelectPerPage}
      />
    )
  }
  const SortLink = ({ name, className, children, ...rest }) => {
    const sorting = params.sort?.field == name
    const order = sorting && params.sort.order == 'asc' ? 'desc' : 'asc'

    return (
      <a
        onClick={() => handleSort(name, order)}
        className={`cursor-pointer ${className}`}
        {...rest}
      >
        {children}
        {sorting && (
          <i
            className={`fad fa-arrow-${
              order == 'asc' ? 'down' : 'up'
            } ml-1 text-primary`}
          />
        )}
      </a>
    )
  }

  const contextProps = {
    records: recordsWithoutDeleted,
    isLoading,
    isError,
    isSuccess,
    isEmpty,
    isReachingEnd,
    mutate,
    refetch,
    size,
    setSize,
    params,
    pagination,
    filtersFormRef,
    ...recordsRest,
    ...rest
  }
  const contextMethods = {
    handleDelete,
    handleSort,
    handleGoToPage,
    handleSelectPerPage,
    isSorting,
    submitFiltersForm,
    setParamsAttrs,
    setFilter,
    resetFilters,
    sort: setSort
  }
  const contextComponents = {
    SortLink,
    FiltersForm,
    Pagination
  }

  return (
    <ResourceRecordsContext.Provider
      value={{
        ...contextProps,
        ...contextMethods,
        ...contextComponents
      }}
    >
      {children}
    </ResourceRecordsContext.Provider>
  )
}

// eslint-disable-next-line react/display-name
const withResourceRecords = (Component, propConfig) => componentProps => {
  const config = _.isFunction(propConfig)
    ? propConfig(componentProps)
    : propConfig

  return (
    <ResourceRecordsProvider {...componentProps} {...config} config={config}>
      <Component config={config} {...componentProps} />
    </ResourceRecordsProvider>
  )
}

export {
  useResourceRecordsContext,
  ResourceRecordsProvider,
  withResourceRecords
}
