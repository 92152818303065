import classNames from 'classnames'
import _ from 'lodash'
import Image from 'next/image'

import { ConditionalWrapper } from '@tootz/react-admin/components'

const Symbol = ({
  imageUrl,
  alt,
  imageAlt,
  label,
  labelClasses: labelClassesFromProps,
  imageClasses: imageClassesFromProps,
  bg = 'white',
  variant = 'primary',
  size = 40,
  circle,
  square,
  ratio = '1by1',
  fit = 'contain',
  asLabel,
  className = '',
  children,
  ...rest
}) => {
  const symbolClasses = classNames(
    'symbol',
    `symbol-${ratio}`,
    `bg-${bg}`,
    'd-flex',
    'align-items-center',
    'justify-content-center',
    'position-relative',
    className,
    ...(_.isObject(size)
      ? Object.keys(size).map(
          key =>
            `symbol${key === 'default' ? '' : '-' + key}-${size[key]}px w${
              key === 'default' ? '' : '-' + key
            }-${size[key] % 5 != 0 && size[key]}px h${
              key === 'default' ? '' : '-' + key
            }-${size[key] % 5 != 0 && size[key]}px`
        )
      : [
          `symbol-${size}px w-${size % 5 != 0 && size}px h-${
            size % 5 == 0 ? '' : size
          }px`
        ]),
    {
      'symbol-circle': circle,
      'symbol-square': square
    }
  )

  const labelClasses = classNames(
    'symbol-label',
    'fs-5',
    'fw-bold',
    `bg-${bg}`,
    `text-${variant}`,
    [`bgi-size-${fit}`],
    'text-uppercase',
    ...(_.isObject(size)
      ? Object.keys(size).map(
          key =>
            `symbol${key === 'default' ? '' : '-' + key}-${size[key]}px w${
              key === 'default' ? '' : '-' + key
            }-${size[key]}px h${key === 'default' ? '' : '-' + key}-${
              size[key]
            }px`
        )
      : [`symbol-${size}px w-${size}px h-${size}px`]),
    labelClassesFromProps
  )

  const imageClasses = classNames(`obj-fit-${fit}`, imageClassesFromProps, {
    'rounded-circle': circle
  })

  return (
    <div className={symbolClasses} {...rest}>
      {imageUrl || label ? (
        <>
          {imageUrl ? (
            <img
              alt={alt || imageAlt || label}
              src={imageUrl}
              className={imageClasses}
            />
          ) : (
            <div className={labelClasses}>{label}</div>
          )}
        </>
      ) : (
        <ConditionalWrapper
          condition={asLabel}
          wrapper={children => (
            <div
              className={labelClasses}
              style={{ backgroundImage: `url(${imageUrl})` }}
            >
              {children}
            </div>
          )}
        >
          {children}
        </ConditionalWrapper>
      )}
    </div>
  )
}

export default Symbol
