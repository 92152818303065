import * as dynamoose from 'dynamoose'
import _ from 'lodash'

import objMap from '../../../utils/objectMap'

import { CouponSchema } from './CouponSchema'

export class CouponRepository {
  dbInstance
  accountId

  constructor(accountId) {
    // dynamoose.logger.providers.set(console);
    if (
      process.env.NODE_ENV == 'test' ||
      process.env.NODE_ENV == 'development'
    ) {
      dynamoose.aws.sdk.config.update({
        accessKeyId: 'abcde',
        secretAccessKey: 'abcde',
        region: 'ap-south-1',
        endpoint: 'http://localhost:8000'
      })
    }

    dynamoose.model.defaults.set({
      create: true,
      waitForActive: {
        enabled: true,
        check: {
          timeout: 1000
        }
      }
    })

    const tableName = `${process.env.NODE_ENV}Coupon`
    this.dbInstance = dynamoose.model(tableName, CouponSchema)
    this.accountId = accountId
  }

  create = async data => await this.dbInstance.create(this.parseData(data))

  update = async data => await this.dbInstance.update(this.parseData(data))

  list = async (query = {}, perPage = 10, lastKey = null) => {
    const response = await this.dbInstance
      .query(this.parseQueryData(query))
      .limit(perPage)
      .startAt(lastKey)
      .exec()
    return {
      records: response,
      pagination: {
        per_page: perPage,
        next_page: response.lastKey
      }
    }
  }

  find = async Id =>
    await this.dbInstance.get({ AccountId: this.accountId, Id: Id })

  delete = async data => await this.dbInstance.delete(this.parseData(data))

  parseQueryData = data => {
    const convertedData = objMap(data, (key, value) => {
      const attribute = key.split('_')[0]
      const matcher = key.split('_')[1]

      let result = {}
      result[this.matchers[matcher]] = value

      return [attribute, result]
    })

    return {
      ...convertedData,
      AccountId: {
        eq: this.accountId
      }
    }
  }

  parseData = data => {
    const parsedData = {
      AccountId: this.accountId,
      ...data
    }

    if (_.has(data, 'ValidUntil'))
      parsedData.ValidUntil = new Date(data.ValidUntil)
    if (_.has(data, 'Discount')) parsedData.Discount = parseInt(data.Discount)
    if (_.has(data, 'MaxUses')) parsedData.MaxUses = parseInt(data.MaxUses)
    if (_.has(data, 'MaxUsesPerUser'))
      parsedData.MaxUsesPerUser = parseInt(data.MaxUsesPerUser)

    return parsedData
  }
}
