import React, { createContext } from 'react'

import { createContextualCan, useAbility } from '@casl/react'
import _ from 'lodash'

const AbilityContext = createContext(null)

const useAbilityContext = () => useAbility(AbilityContext)
const getAbilityContext = useAbilityContext

const AbilityProvider = ({ ability, children }) => (
  <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
)

const Can = createContextualCan(AbilityContext.Consumer)

export { useAbilityContext, getAbilityContext, AbilityProvider, Can }
