import React, { createContext, useContext, useState } from 'react'

import _ from 'lodash'

const AdminContext = createContext(null)

const useAdminContext = () => useContext(AdminContext)

const AdminProvider = ({
  brand,
  logo,
  containerFluid = true,
  toolbar = {
    disabled: false,
    horizontal: true
  },
  header = {},
  sidebar,
  footer = {},

  enableColorModes = false, // Experimental

  navbarMenuItems, // Deprecated, use header.navbarMenuItems instead
  topbarItems, // Deprecated, use topbar.items instead
  hideAside = false, // Deprecated, use aside.disabled instead
  asideMenuItems, // Deprecated, use aside.menuItems instead
  asidePreMenu, // Deprecated, use aside.preMenu instead

  children,
  ...rest
}) => {
  const [showModal, setShowModal] = useState(null)
  const sidebarTheme = ['light', 'dark'].includes(sidebar?.theme)
    ? sidebar?.theme
    : 'dark'
  const contextAttrs = {
    brand,
    logo,
    containerFluid,
    enableColorModes,
    header: {
      navbarMenuItems: navbarMenuItems,
      topbarItems: topbarItems,
      containerFluid: containerFluid,
      ...header
    },
    toolbar: {
      ...toolbar,
      containerFluid: containerFluid
    },
    sidebar: {
      disabled: hideAside,
      preMenu: asidePreMenu,
      menuItems: asideMenuItems,
      ...sidebar,
      theme: sidebarTheme
    },
    footer: { ...footer }
  }

  const getLogo = variant => {
    if (!variant) {
      if (sidebarTheme == 'dark') return logo.light || logo

      return logo.default || logo
    }

    return _.get(logo, variant)
  }
  const getLogoIcon = variant => {
    if (!variant) return logo.icon.default || logo.icon.default

    return _.get(logo, `icon.${variant}`)
  }

  const Modal = ({ component, onHide, onSave, ...rest }) => {
    const Component = component

    return (
      <Component
        {...rest}
        show
        onHide={() => {
          onHide && onHide()
          setShowModal(null)
        }}
        onSave={record => {
          onSave && onSave(record)
          setShowModal(null)
        }}
      />
    )
  }

  return (
    <AdminContext.Provider
      value={{
        ...contextAttrs,
        showModal,
        setShowModal,
        getLogo,
        getLogoIcon,
        ...rest
      }}
    >
      {children}
      {showModal && <Modal {...showModal} />}
    </AdminContext.Provider>
  )
}

export { useAdminContext, AdminProvider }
