import { useAdminContext } from '../../../context/AdminContext'
import { filterMenuItemsByCondition } from '../../../helpers'

import SidebarMenuItem from './Item'

const SidebarMenu = () => {
  const {
    sidebarMenuItems: _sidebarMenuItems,
    sidebar: { menuItems, scrollOffset }
  } = useAdminContext()

  const sidebarMenuItems = _sidebarMenuItems || menuItems || []

  return (
    <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
      <div
        id="ttz_app_sidebar_menu_wrapper"
        className="app-sidebar-wrapper hover-scroll-overlay-y my-5"
        data-ttz-scroll="true"
        data-ttz-scroll-activate="true"
        data-ttz-scroll-height="auto"
        data-ttz-scroll-dependencies="#ttz_app_sidebar_logo, #ttz_app_sidebar_footer"
        data-ttz-scroll-wrappers="#ttz_app_sidebar_menu"
        data-ttz-scroll-offset={scrollOffset || '5px'}
        data-ttz-scroll-save-state="true"
      >
        <div
          id="#ttz_app_sidebar_menu"
          className="menu menu-column menu-rounded menu-sub-indention px-3"
          data-ttz-menu="true"
          data-ttz-menu-expand="false"
        >
          {filterMenuItemsByCondition(sidebarMenuItems).map(item => (
            <SidebarMenuItem key={item.slug} {...item} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SidebarMenu
