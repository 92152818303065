import _ from 'lodash'

const manageReferenceFields = async data => {
  if (_.has(data, 'reference_fields') && _.isArray(data['reference_fields'])) {
    let referenceFields = data['reference_fields'].filter(
      w => w != null && w != '' && w != undefined
    )
    referenceFields = _.reverse(
      _.sortBy(_.uniq(referenceFields), [w => w?.length])
    )

    let newData = { ...data }
    referenceFields.forEach(key => {
      if (_.has(data, key)) {
        _.set(newData, `${key}_attributes`, { ..._.get(newData, key) })
        newData = _.omit(newData, [key])
      }
    })

    return newData
  }

  return data
}

export default manageReferenceFields
