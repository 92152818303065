import React, { forwardRef } from 'react'

import Link from 'next/link'
import Dropdown from 'react-bootstrap/Dropdown'

// eslint-disable-next-line react/display-name
const CustomToggle = forwardRef(({ className, children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    className={className}
    onClick={e => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
  </a>
))

const DropdownToggle = ({
  size = 'sm',
  variant = 'light',
  color = '',
  activeColor = '',
  active = '',
  flush,
  className,
  customToggle,
  children,
  ...rest
}) => (
  <Dropdown.Toggle
    as={customToggle ? CustomToggle : 'button'}
    type="button"
    className={
      flush || customToggle
        ? className
        : `btn btn-${size} btn-${variant} btn-color-${color} btn-active-color-${activeColor} btn-active-${active} cursor-pointer ${className}`
    }
    {...rest}
  >
    {children}
  </Dropdown.Toggle>
)

const DropdownMenu = ({ children, className, width = 200, ...rest }) => (
  <Dropdown.Menu
    className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-${width}px py-3 m-0 ${className}`}
    {...rest}
  >
    {children}
  </Dropdown.Menu>
)

const DropdownHeader = ({ children, className, ...rest }) => (
  <>
    <Dropdown.Item as="div" className="menu-item px-3">
      <Dropdown.Header
        className={`menu-content fs-6 text-dark fw-bolder px-3 pb-4 pt-1 ${className}`}
        {...rest}
      >
        {children}
      </Dropdown.Header>
    </Dropdown.Item>
    <div className="separator mb-3 opacity-75" />
  </>
)

const DropdownSectionTitle = ({ children, className, ...rest }) => (
  <Dropdown.Item as="div" className="menu-item px-3">
    <Dropdown.Header
      className={`menu-content text-muted pb-2 px-3 fs-4 text-uppercase ${className}`}
      {...rest}
    >
      {children}
    </Dropdown.Header>
  </Dropdown.Item>
)

const WithLink = ({ href, children, ...rest }) =>
  href ? (
    <Link href={href} {...rest}>
      {children}
    </Link>
  ) : (
    children
  )

const DropdownLink = ({ children, href, className, ...rest }) => (
  <Dropdown.Item as="div" className="menu-item px-3">
    <WithLink
      href={href}
      className={`menu-link flex-stack--disabled px-3 btn btn-sm w-100 ${className}`}
      {...rest}
    >
      {children}
    </WithLink>
  </Dropdown.Item>
)

const DropdownButton = ({ onClick, className, children, ...rest }) => (
  <Dropdown.Item as="div" className="menu-item px-3">
    <button
      type="button"
      className={`menu-link flex-stack--disabled px-3 btn btn-sm w-100 ${className}`}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  </Dropdown.Item>
)

const DropdownContent = ({ children, className, ...rest }) => (
  <Dropdown.Item as="div" className="menu-item px-3">
    <div className={`menu-content px-3 py-3 ${className}`} {...rest}>
      {children}
    </div>
  </Dropdown.Item>
)

const DropdownDivider = ({ className, ...rest }) => (
  <div className={`separator my-3 opacity-75 ${className}`} {...rest} />
)

const TTZDropdown = ({ children, align = 'end', className, ...rest }) => {
  return (
    <Dropdown
      align={align}
      className={`ttz-dropdown ${className}`}
      onClick={e => {
        e.stopPropagation()
      }}
      {...rest}
    >
      {children}
    </Dropdown>
  )
}

TTZDropdown.Toggle = DropdownToggle
TTZDropdown.Menu = DropdownMenu
TTZDropdown.Header = DropdownHeader
TTZDropdown.SectionTitle = DropdownSectionTitle
TTZDropdown.Link = DropdownLink
TTZDropdown.Button = DropdownButton
TTZDropdown.Content = DropdownContent
TTZDropdown.Divider = DropdownDivider

export default TTZDropdown
