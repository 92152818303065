import React, { forwardRef, useEffect, useRef } from 'react'

import { useRouter } from 'next/router'
import StepWizard from 'react-step-wizard'
import _ from 'lodash'

import { useDataProviderContext } from '../../context/DataProviderContext'
import { useResourceRecordContext } from '../../context/ResourceRecordContext'
import handleSubmit from '../ResourceRecordForm/handleSubmit'

import ResourceRecordFormWizardStep from './Step'

const ResourceRecordFormWizard = (
  {
    beforeSave,
    afterSave,
    onSave,
    onSubmit,
    onError,
    defaultValues,
    children,
    ...rest
  },
  ref
) => {
  const router = useRouter()
  const { dataProvider } = useDataProviderContext()
  const {
    apiBasePath,
    id,
    record,
    setSavedRecord,
    isEdit,
    setIsSubmitting,
    mutate
  } = useResourceRecordContext()
  let fullRecord = useRef()

  const onSubmitStep = data => {
    const _record = { ...record, ...data }
    setSavedRecord(_record)

    if (ref.current.totalSteps === ref.current.currentStep) {
      handleSubmit({
        apiBasePath,
        beforeSave,
        data,
        dataProvider,
        id,
        isEdit,
        mutate,
        record: fullRecord?.current,
        onError,
        onSave,
        onSubmit,
        router,
        setIsSubmitting,
        setSavedRecord
      })
    } else ref.current.nextStep()
  }

  useEffect(() => {
    fullRecord.current = { ...record }
  }, [])

  return (
    <>
      <StepWizard ref={ref} className="step-wizard h-100" {...rest}>
        {React.Children.map(children, child => (
          <ResourceRecordFormWizardStep
            onSubmitStep={onSubmitStep}
            defaultValues={defaultValues}
            {...child.props}
          >
            {React.cloneElement(child)}
          </ResourceRecordFormWizardStep>
        ))}
      </StepWizard>
    </>
  )
}

export default forwardRef(ResourceRecordFormWizard)
