import _ from 'lodash'

// import Search from './Search'
// import Activities from './Activities'
import Notifications from './Notifications'
// import QuickLinks from './QuickLinks'
import QuickUser from './QuickUser'
import { ColorModes } from './ColorModes'

const TopBarItem = ({ slug, config, content, component, ...rest }) => {
  const components = {
    notifications: Notifications,
    colorModes: ColorModes,
    'quick-user': QuickUser
  }

  if (content) return content
  if (component) return component

  const Component = components[slug]
  if (Component) return <Component {...config} {...rest} />

  return null
}

export default TopBarItem
