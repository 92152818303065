import React from 'react'

import PaginationToolbar from './Toolbar'

const getPages = ({ page, pageCount, paginationSize }) => {
  const result = []

  if (!page) return result

  if (pageCount === 1) return [1]

  if (pageCount < page) return result

  if (pageCount < paginationSize + 1) {
    for (let i = 1; i < pageCount + 1; i++) {
      result.push(i)
    }

    return result
  }

  if (page === 1) {
    for (let i = 1; i < paginationSize + 1; i++) {
      if (i < pageCount) {
        result.push(i)
      }
    }

    return result
  }

  if (page === pageCount) {
    for (let i = pageCount - paginationSize + 1; i <= pageCount; i++) {
      if (i <= pageCount) {
        result.push(i)
      }
    }

    return result
  }

  const shiftCount = Math.floor(paginationSize / 2)
  if (shiftCount < 1) {
    result.push(page)
    return result
  }

  if (page < shiftCount + 2) {
    for (let i = 1; i < paginationSize + 1; i++) {
      result.push(i)
    }
    return result
  }

  if (pageCount - page < shiftCount + 2) {
    for (let i = pageCount - paginationSize; i < pageCount + 1; i++) {
      result.push(i)
    }
    return result
  }

  for (let i = page - shiftCount; i < page; i++) {
    if (i > 0) {
      result.push(i)
    }
  }
  result.push(page)
  for (let i = page + 1; i < page + shiftCount + 1; i++) {
    if (i <= pageCount) {
      result.push(i)
    }
  }

  return result
}

const Pagination = ({
  page,
  perPage,
  pageCount,
  totalCount,
  isLoading,
  goToPage,
  onSelectPerPage,
  paginationSize = 5
}) => {
  const pages = getPages({ page, pageCount, paginationSize: paginationSize })

  const disabled = pageCount <= 1

  const goToFirstPage = () => goToPage(1)
  const goToPrevPage = () => goToPage(page - 1)
  const goToNextPage = () => goToPage(page + 1)
  const goToLastPage = () => goToPage(pageCount)

  return (
    <div className="d-flex align-items-center flex-wrap justify-content-md-between flex-column flex-md-row">
      <div
        className={`d-flex flex-nowrap mx-md-0 me-3 ${
          disabled ? 'disabled' : ''
        }`}
      >
        <a
          onClick={goToFirstPage}
          className={`btn btn-icon btn-sm btn-light btn-hover-primary me-2 ${
            page == 1 ? 'disabled' : ''
          }`}
        >
          <i className="fad fa-chevron-double-left" />
        </a>
        <a
          onClick={goToPrevPage}
          className={`btn btn-icon btn-sm btn-light btn-hover-primary me-2 ${
            page <= 1 ? 'disabled' : ''
          }`}
        >
          <i className="fad fa-chevron-left" />
        </a>

        {page >= paginationSize - 1 && (
          <a className="btn btn-icon btn-sm border-0 btn-hover-primary me-2 ">
            ...
          </a>
        )}

        {pages.map(p => (
          <a
            key={p}
            onClick={() => page !== p && goToPage(p)}
            className={`btn btn-icon btn-sm border-0 btn-light ${
              page === p ? ' btn-hover-primary active' : ''
            } me-2 `}
          >
            {p}
          </a>
        ))}

        {page < pageCount && (
          <a className="btn btn-icon btn-sm border-0 btn-hover-primary me-2 d-none d-md-flex">
            ...
          </a>
        )}
        <a
          onClick={goToNextPage}
          className={`btn btn-icon btn-sm btn-light btn-hover-primary d-none d-md-flex me-2 ${
            page < pageCount ? '' : 'disabled'
          }`}
        >
          <i className="fad fa-chevron-right"></i>
        </a>
        <a
          onClick={goToLastPage}
          className={`btn btn-icon btn-sm btn-light btn-hover-primary ${
            page == pageCount ? 'disabled' : ''
          }`}
        >
          <i className="fad fa-chevron-double-right"></i>
        </a>
      </div>
      <PaginationToolbar
        isLoading={isLoading}
        perPage={perPage}
        totalCount={totalCount}
        onSelectPerPage={onSelectPerPage}
      />
    </div>
  )
}

export default Pagination
