import _ from 'lodash'

const manageNestedFields = async (data, record) => {
  if (_.has(data, 'nested_fields') && _.isArray(data['nested_fields'])) {
    let nestedFields = data['nested_fields'].filter(
      w => w != null && w != '' && w != undefined
    )
    nestedFields = _.sortBy(_.uniq(nestedFields), [w => w?.length])

    const newData = { ...data }
    // Somente funciona para arrays, precisa implementar para objeto
    nestedFields.forEach(key => {
      if (_.has(record, key)) {
        const fieldsOnRecord = _.get(record, key, [])

        if (fieldsOnRecord) {
          const fieldsOnData = _.get(data, key, [])
          const toDestroyItems = []
          fieldsOnRecord.forEach(field => {
            if (!_.find(fieldsOnData, { id: field.id }) && field.id)
              toDestroyItems.push({ id: field.id, _destroy: true })
          })

          _.set(newData, key, [..._.get(data, key, []), ...toDestroyItems])
        }
      }
    })

    return newData
  }

  return data
}

export default manageNestedFields
