import Link from 'next/link'

import { useAdminContext } from '../../context/AdminContext'

const SidebarLogo = () => {
  const { brand, getLogo, getLogoIcon, sidebar } = useAdminContext()
  const logo = getLogo()
  const logoIcon = getLogoIcon()
  const customLogoClassNames = _.get(sidebar, 'logo.className')

  return (
    <div id="ttz_app_sidebar_logo" className="app-sidebar-logo px-6">
      <Link href="/">
        <img
          alt={brand.name}
          src={logo}
          className={`app-sidebar-logo-default ${
            customLogoClassNames || 'h-30px'
          }`}
        />
        <img
          alt={brand.name}
          src={logoIcon}
          className={`app-sidebar-logo-minimize ${
            customLogoClassNames || 'h-30px'
          }`}
        />
      </Link>

      <div
        id="ttz_app_sidebar_toggle"
        className="app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate "
        data-ttz-toggle="true"
        data-ttz-toggle-state="active"
        data-ttz-toggle-target="body"
        data-ttz-toggle-name="app-sidebar-minimize"
      >
        <i className="fad fa-chevron-double-left rotate-180" />
      </div>
    </div>
  )
}

export default SidebarLogo
