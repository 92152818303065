import _ from 'lodash'

import { removeQuestionMarkFromObjKeys } from '../../../utils'
import createIuguCustomer from '../gateways/iugu/customers/create'
import getErrorResponse from '../services/getErrorResponse'

const updateCustomer = async ({
  account, 
  customer_id,
  email,
  name,
  notes,
  phone,
  phone_prefix,
  cpf_cnpj,
  zip_code,
  number,
  street,
  city,
  state,
  district,
  complement,
}) => {

  const apiToken =
    account[process.env.NODE_ENV == 'development' ? 'test_token' : 'live_token']

  const data = {
    apiToken,
    customer_id,
    email,
    name,
    notes,
    phone,
    phone_prefix,
    cpf_cnpj,
    zip_code,
    number,
    street,
    city,
    state,
    district,
    complement,
  }

  try {
    const customer = await createIuguCustomer(data)

    return {
      status: 200,
      body: {
        record: removeQuestionMarkFromObjKeys(customer)
      }
    }
  } catch (error) {
    const status = _.get(error, 'response.status', 500)

    return getErrorResponse('CREATE_CUSTOMER', status, error)
  }
}

export default updateCustomer
