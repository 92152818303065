import _ from 'lodash'
import qs from 'qs'
import { useQuery } from '@tanstack/react-query'
import { color } from 'console-log-colors'

const isDev = process.env.NODE_ENV == 'development'

export const DEFAULT_OPTIONS = {
  retry: false,
  retryDelay: attempt =>
    Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000)
}

export const useRecord = (
  props,
  /**
   * @DEPRECATED
   * Use useRecord({r: '', id: 1, options: {}, ...etc}) instead
   **/
  DREPRECATED_id,
  DEPRECATED_options = DEFAULT_OPTIONS,
  DEPRECATED_forceIdPresence = true,
  DEPRECATED_shouldFetch = true,
  { query: DEPRECATED_query, action: DEPRECATED_action } = {}
) => {
  /**
   * @DEPRECATED
   * Manage to support deprecated sequential args
   */
  if (!_.isObject(props)) {
    if (isDev)
      console.warn(
        color.magenta('[REACT-ADMIN]'),
        'Consider use useRecord with one named props arg instead of using many sequential args'
      )

    props = {
      r: props,
      id: DREPRECATED_id,
      options: DEPRECATED_options,
      forceIdPresence: DEPRECATED_forceIdPresence,
      shouldFetch: DEPRECATED_shouldFetch,
      query: DEPRECATED_query,
      action: DEPRECATED_action
    }
  }

  let {
    resource,
    r,
    id,
    options = DEFAULT_OPTIONS,
    forceIdPresence = true,
    shouldFetch = true,
    query,
    params,
    action
  } = props
  resource = resource || r // Accepts both resource or r as base path
  forceIdPresence = typeof forceIdPresence == 'undefined' || forceIdPresence // If undefined, always true
  shouldFetch = typeof shouldFetch == 'undefined' || shouldFetch // If undefined, always true
  params = params || query // Accepts both params or query as query params

  let path = _.isFunction(resource)
    ? resource(id)
    : `${resource}${id ? '/' + id : ''}`
  if (action) path += `/${action}`
  if (params)
    path += `${path.includes('?') ? '&' : '?'}${qs.stringify(params, {
      arrayFormat: 'brackets'
    })}`
  const somethingToFetch = !!id || !forceIdPresence
  const readyToFetch =
    shouldFetch && somethingToFetch && !!resource && somethingToFetch

  let { isLoading, isError, isSuccess, data, refetch, ...rest } = useQuery({
    queryKey: [path],
    enabled: !!readyToFetch,
    ...DEFAULT_OPTIONS,
    ...options
  })
  const record = data?.record
  const errors = _.get(data, 'errors')
  const errorStatusCode = _.get(rest, 'failureReason.response.status')
  const supInfo = _.omit(data, ['record', 'errors'])

  // if (!somethingToFetch) {
  //   isLoading = false
  //   isError = false
  //   isSuccess = true
  // }

  return {
    record,
    errors,
    errorStatusCode,
    isLoading,
    isError,
    isSuccess,
    loadWrapperConfig: { isLoading, isError, isSuccess },
    mutate: refetch,
    refetch,
    ...supInfo,
    ...rest
  }
}

export default useRecord
