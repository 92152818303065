import _ from 'lodash'

import objKeysToSnakeCase from '../utils/objKeysToSnakeCase'

const getQuery = ({
  pagination = { page: 1, perPage: 12 },
  filter = {},
  sort,
  ...rest
}) => {
  const { page, per_page } = objKeysToSnakeCase(pagination)
  const query = {
    page,
    per_page,
    q: {
      ...filter,
      s: 'created_at desc'
    },
    ...rest
  }

  if (sort) {
    if (_.isArray(sort)) {
      query.q.s = _.map(sort, s => `${s.field} ${s.order}`)
    } else query.q.s = `${sort.field} ${sort.order}`
  }

  return query
}

export default getQuery
