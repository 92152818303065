import _ from 'lodash'

import { removeQuestionMarkFromObjKeys } from '../../../utils'
import createIuguPaymentMethod from '../gateways/iugu/paymentMethod/create'
import getErrorResponse from '../services/getErrorResponse'

const createPaymentMethod = async ({ account, customer_id, description, token }) => {

  const apiToken =
    account[process.env.NODE_ENV == 'development' ? 'test_token' : 'live_token']

  const data = {
    apiToken,
    customer_id,
    description,
    token,
  }

  try {
    const invoice = await createIuguPaymentMethod(data)

    return {
      status: 200,
      body: {
        record: removeQuestionMarkFromObjKeys(invoice)
      }
    }
  } catch (error) {
    const status = _.get(error, 'response.status', 500)

    return getErrorResponse('CREATE_PAYMENT_METHOD', status, error)
  }
}

export default createPaymentMethod
