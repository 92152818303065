import { filterMenuItemsByCondition } from '../../../../helpers'

import MenuItem from './Item'

const MenuItemSubAccordion = ({ items, level }) => {
  const newLevel = level + 1

  return (
    <div className="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown px-lg-2 py-lg-4 w-lg-250px">
      {filterMenuItemsByCondition(items).map(({ slug, ...rest }) => (
        <MenuItem key={slug} level={newLevel} {...rest} />
      ))}
    </div>
  )
}

export default MenuItemSubAccordion
