import { defineAbility } from '@casl/ability'
import _ from 'lodash'

import {
  User,
  Federation,
  Core,
  Ej,
  Contract,
  ConnectionProduct,
  ConnectionProductCategory,
  CommunitiesPost
} from './entities'

const useAbility = user =>
  defineAbility(can => {
    if (!user) return

    const roles = _.get(user, 'roles', [user.main_role])

    roles.forEach(role => {
      const roleName = _.get(role, 'name')
      const resourceType = _.get(role, 'resource_type')
      const resourceId = _.get(role, 'resource_id')

      const systemAdmin =
        ['system_admin'].includes(roleName) &&
        (!resourceType || resourceType === 'Brasil Júnior')
      const admin =
        ['admin'].includes(roleName) &&
        (!resourceType || resourceType === 'Brasil Júnior')
      const adminEvents =
        ['admin_events'].includes(roleName) &&
        (!resourceType || resourceType === 'Brasil Júnior')
      const partnerManager =
        ['admin'].includes(roleName) && resourceType === 'Partner'
      const universityManager =
        ['admin', 'sub_dean', 'dean', 'teacher'].includes(roleName) &&
        resourceType === 'University'
      const federationManager =
        ['admin', 'director'].includes(roleName) &&
        resourceType === 'Federation'
      const coreManager =
        ['admin'].includes(roleName) && resourceType === 'Core'

      if (systemAdmin || admin) {
        if (systemAdmin) can('manage', 'System')

        can('manage', 'all')
        can('manage', 'Partner')
        can('manage', 'University')
        can('manage', 'Federation')
        can('manage', 'Core')
        can('manage', 'Ej')
        can('manage', 'User')

        can('audit', 'Contract')
        can('audit', 'InstanceContract')

        can('manage', 'ConnectionProduct')
        can('manage', 'CommunitiesPost')

        can('manage', 'Event')

        // Modules
        can('manage', 'Events')
        can('manage', 'ConnectionProducts')
      } else {
        if (adminEvents) can('manage', 'Event')
        if (resourceType === 'Partner') {
          if (partnerManager) can('manage', 'Partner', { id: resourceId })
        } else if (universityManager) {
          can('manage', 'University', { id: resourceId })
        } else if (federationManager) {
          can('manage', 'Federation', { id: resourceId })
          can('manage', 'Core', { federation_id: resourceId })
          can('manage', 'Ej', { federation_id: resourceId })

          can('create', 'ConnectionProduct')
          can('manage', 'ConnectionProduct', {
            connection_productable_id: resourceId,
            connection_productable_type: 'Federation'
          })
        } else if (coreManager) {
          can('manage', 'Core', { id: resourceId })
          can('manage', 'Ej', { core_ids: { $all: [resourceId] } })

          can('create', 'ConnectionProduct')
          can('manage', 'ConnectionProduct', {
            connection_productable_id: resourceId,
            connection_productable_type: 'Core'
          })
        } else if (resourceType === 'Ej') {
          const ejManager = ['admin', 'director'].includes(roleName)
          const ejMember = ['member', 'trainee'].includes(roleName)
          const ejPostJunior = ['post_junior'].includes(roleName)

          if (ejManager) can('manage', 'Ej', { id: resourceId })
        }

        can('manage', 'User', { id: user.id })
        can('manage', 'CommunitiesPost', { user_id: user.id })
      }
    })
  })

export default useAbility
export {
  User,
  Federation,
  Core,
  Ej,
  Contract,
  ConnectionProduct,
  ConnectionProductCategory,
  CommunitiesPost
}
