import React, { useEffect, useRef } from 'react'

import { yupResolver } from '@hookform/resolvers/yup'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useForm, FormProvider } from 'react-hook-form'

import { useDataProviderContext } from '../../context/DataProviderContext'
import { useResourceRecordContext } from '../../context/ResourceRecordContext'

import handleSubmit from './handleSubmit'

const ResourceRecordForm = ({
  beforeSave,
  onSave,
  onSubmit,
  onError,
  resetOnSave = false,
  defaultValues,
  useFormConfig = {},
  validationSchema,
  customAction: customFormAction,
  children,
  ...rest
}) => {
  const formRef = useRef()
  const router = useRouter()
  const { dataProvider } = useDataProviderContext()
  const {
    apiBasePath,
    id,
    record,
    setSavedRecord,
    isEdit,
    setIsSubmitting,
    customAction,
    refetch
  } = useResourceRecordContext()
  const methods = useForm({
    defaultValues: isEdit ? record : defaultValues || {},
    shouldUnregister: true,
    shouldFocusError: true,
    criteriaMode: 'all',
    resolver: validationSchema ? yupResolver(validationSchema) : null,
    ...useFormConfig
  })

  const submitForm = () => {
    // This is not working, so we made some workarounds
    // filtersFormRef.current.dispatchEvent(
    //   new Event("submit", { cancelable: true })
    // );
    formRef.current.querySelector('input[type=submit]').click()
  }

  useEffect(() => {
    if (record && isEdit) {
      methods.reset(record)
    }
  }, [record])

  return (
    <FormProvider submitForm={submitForm} {...methods}>
      <form
        ref={formRef}
        onSubmit={methods.handleSubmit(data =>
          handleSubmit({
            apiBasePath,
            beforeSave,
            data,
            record,
            dataProvider,
            id,
            isEdit,
            customAction: customFormAction || customAction,
            methods,
            refetch,
            onError,
            onSave,
            onSubmit,
            resetOnSave,
            router,
            setIsSubmitting,
            setSavedRecord
          })
        )}
        {...rest}
      >
        {children}
        <input type="submit" hidden />
      </form>
    </FormProvider>
  )
}

export default ResourceRecordForm
