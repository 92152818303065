import _ from 'lodash'
import { DateTime } from 'luxon'

import { removeQuestionMarkFromObjKeys } from '../../../utils'
import createIuguInvoice from '../gateways/iugu/invoices/create'
import getErrorResponse from '../services/getErrorResponse'

import { MASTER_KEY, SELLING_MODES } from './config'
import { getDiscount, applyDiscount } from './getDiscount'
import getPrice from './getPrice'
import getSplit from './getSplit'

const ITEM_ALLOWED_PARAMS = ['description', 'quantity', 'price_cents']

const createInvoice = async (params) => {
  let {
    account,
    selling_mode,
    payment_method,
    due_date,
    payer,
    items,
    installments,
    credit_card,
    customer_id,
    order_id,
    external_reference,
    client_id,
    user_id,
    coupon,
    checkoutReturnUrl,
    email
  } = params

  if (!SELLING_MODES.includes(selling_mode)) {
    Sentry.captureException(
      new Error(
        `Payment method '${selling_mode}' provided is not allowed on Tootz Payments `
      )
    )

    return getErrorResponse('SELLING_MODE_NOT_ALLOWED_ERROR', 400)
  }

  if (!items || !_.isArray(items) || items.length == 0) {
    Sentry.captureException(
      new Error(
        `Charging without items (${JSON.stringify(
          items
        )}) is not supported by Tootz Payments`
      )
    )

    return getErrorResponse('EMPTY_ORDER_ITEMS_ERROR', 500)
  }

  /**
   * Calculate price cents and generate the splits
   */
  let totalSplitCents = 0
  let totalCents = 0
  items = items.map(item => {
    const price = getPrice(selling_mode, item.net_price_cents * item.quantity)
    item.price_cents = price.cents / item.quantity

    totalCents += price.cents

    const itemSplitCents = getSplit(
      selling_mode,
      item.net_price_cents * item.quantity,
      payment_method,
      installments
    )
    totalSplitCents += itemSplitCents

    return _.pick(item, ITEM_ALLOWED_PARAMS)
  })
  const split = {
    recipient_account_id: MASTER_KEY,
    cents: totalSplitCents
  }
  const splits = [split]

  /**
   * Discount coupon
   */
  const discount_cents = coupon ? await getDiscount(account.id, coupon, user_id, totalCents) : 0

  const custom_variables = [
    { name: 'client_id', value: client_id },
    { name: 'user_id', value: user_id },
    { name: 'external_reference', value: external_reference },
    { name: 'discount_cents', value: discount_cents }
  ]

  splits.forEach((split, index) => {
    custom_variables.push({
      name: `split_${index}_recipient_account_id`,
      value: split.recipient_account_id
    })
    custom_variables.push({
      name: `split_${index}_cents`,
      value: split.cents
    })
  })

  const return_url = ''
  const expired_url = ''
  let dueDateTime = due_date
    ? DateTime.fromISO(due_date)
    : DateTime.now().setZone('America/Recife').plus({ days: 3 })
  const apiToken =
    account[process.env.NODE_ENV == 'development' ? 'test_token' : 'live_token']
  const data = {
    apiToken,
    account,
    order_id: order_id,
    due_date: dueDateTime.toISODate(),
    payer,
    payment_method,
    items,
    credit_card,
    installments,
    discount_cents,
    return_url,
    expired_url,
    custom_variables,
    splits,
    customer_id,
    email
  }

  try {
    const invoice = await createIuguInvoice(data)
    if (coupon) await applyDiscount(account.id, coupon, user_id, totalCents)

    return {
      status: 200,
      body: {
        record: removeQuestionMarkFromObjKeys(invoice)
      }
    }
  } catch (error) {
    const status = _.get(error, 'response.status', 500)

    return getErrorResponse('CREATE_INVOICE', status, error)
  }
}

export default createInvoice