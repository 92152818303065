import Admin from './components/Admin'
import Toolbar from './components/Toolbar'
import {
  AbilityProvider,
  useAbilityContext,
  getAbilityContext,
  Can
} from './context/AbilityContext'
import { useAdminContext } from './context/AdminContext'
import {
  DataProviderProvider,
  useDataProviderContext
} from './context/DataProviderContext'
import {
  useResourceRecordContext,
  ResourceRecordProvider,
  withResourceRecord
} from './context/ResourceRecordContext'
import {
  useResourceRecordsContext,
  ResourceRecordsProvider,
  withResourceRecords
} from './context/ResourceRecordsContext'
import {
  useSessionContext,
  SessionProvider,
  withSession
} from './context/SessionContext'
import { ModalProvider, useModalContext } from './context/ModalContext'
import useData from './hooks/useData'
import useDataProvider from './hooks/useDataProvider'
import useRecord from './hooks/useRecord'
import useRecords from './hooks/useRecords'
import useRecordsInfinite from './hooks/useRecordsInfinite'
import { fetchSession, useSession } from './hooks/useSession'
import fileToBase64 from './modules/form/utils/fileToBase64'
import getInputClasses from './modules/form/utils/getInputClasses'
import createCustomer from './modules/payments/core/createCustomer'
import createInvoice from './modules/payments/core/createInvoice'
import createPaymentMethod from './modules/payments/core/createPaymentMethod'
import getCustomer from './modules/payments/core/getCustomer'
import updateCustomer from './modules/payments/core/updateCustomer'
import cookie from './services/cookie'
import getCSSVariableValue from './services/getCSSVariableValue'
import getErrorMessage from './services/getErrorMessage'
import getQuery from './services/getQuery'
import showErrorAlert from './services/showErrorAlert'
import CustomError from './services/customErrors/CustomError'
import { ReleaseVersionRefreshMAnager } from './components/ReleaseVersionRefreshManager'

export {
  ReleaseVersionRefreshMAnager,
  DataProviderProvider,
  useDataProviderContext,
  useResourceRecordsContext,
  ResourceRecordsProvider,
  withResourceRecords,
  useResourceRecordContext,
  ResourceRecordProvider,
  withResourceRecord,
  useSessionContext,
  SessionProvider,
  withSession,
  ModalProvider,
  useModalContext,
  AbilityProvider,
  useAbilityContext,
  useAdminContext,
  getAbilityContext,
  Can,
  useData,
  useDataProvider,
  useRecord,
  useRecords,
  useRecordsInfinite,
  getErrorMessage,
  CustomError,
  showErrorAlert,
  getQuery,
  cookie,
  getCSSVariableValue,
  fileToBase64,
  getInputClasses,
  Admin,
  Toolbar,
  fetchSession,
  useSession,
  createInvoice,
  getCustomer,
  createCustomer,
  updateCustomer,
  createPaymentMethod
}
