import React from 'react'

import classNames from 'classnames'

const Separator = ({ className, size, color, style, children }) => {
  const classes = classNames({
    [`border-${size}`]: size,
    [`border-${color}`]: color,
    [`separator-${style}`]: style,
    'separator-content': children
  })

  return <div className={`separator ${classes} ${className}`}>{children}</div>
}

export default Separator
