import { AbilityProvider } from '../context/AbilityContext'
import { AdminProvider } from '../context/AdminContext'
import { DataProviderProvider } from '../context/DataProviderContext'
import { SessionProvider } from '../context/SessionContext'
import { ModalProvider } from '../context/ModalContext'

import AdminLayout from './Layout'

const Admin = ({
  httpClient,
  fetcher,
  dataProvider,
  session,
  ability,

  brand,
  logo,
  containerFluid,
  header,
  toolbar,
  sidebar,
  footer,

  enableColorModes,

  aside, // Use sidebar instead
  navbarMenuItems, // Deprecated, use header.navbarMenuItems instead
  topbarItems, // Deprecated, use header.topbarItems instead
  sidebarMenuItems, // Deprecated, use sidebar.menuItems instead
  sidebarPreMenu, // Deprecated, use sidebar.preMenu instead
  hideAside, // Deprecated, use sidebar.disabled instead

  selfLayout,

  children
}) => {
  return (
    <AdminProvider
      logo={logo}
      brand={brand}
      containerFluid={containerFluid}
      enableColorModes={enableColorModes}
      footer={footer}
      sidebar={sidebar || aside}
      header={header}
      toolbar={toolbar}
      topbarItems={topbarItems}
      sidebarPreMenu={sidebarPreMenu}
      sidebarMenuItems={sidebarMenuItems}
      navbarMenuItems={navbarMenuItems}
      hideAside={hideAside}
    >
      <AbilityProvider ability={ability}>
        <DataProviderProvider
          httpClient={httpClient}
          fetcher={fetcher}
          dataProvider={dataProvider}
        >
          <SessionProvider session={session}>
            <ModalProvider>
              <AdminLayout selfLayout={selfLayout}>{children}</AdminLayout>
            </ModalProvider>
          </SessionProvider>
        </DataProviderProvider>
      </AbilityProvider>
    </AdminProvider>
  )
}

Admin.propTypes = {}

export default Admin
