import Link from 'next/link'
import { useRouter } from 'next/router'

import MenuItemBullet from './ItemBullet'
import MenuItemIcon from './ItemIcon'

const MenuItemLink = ({ href, path, target = '_self', icon, title, level }) => {
  const router = useRouter()
  const hrefOrPath = href || path
  const isActive = router.pathname === hrefOrPath

  if (hrefOrPath)
    return (
      <Link
        href={hrefOrPath}
        className={`menu-link ${isActive ? 'active' : ''} py-3`}
        target={target}
      >
        {icon && level > 1 && <MenuItemIcon icon={icon} />}
        {!icon && level > 1 && <MenuItemBullet />}
        <span className="menu-title">{title}</span>
      </Link>
    )

  return (
    <span className="menu-link py-3">
      {icon && level > 1 && <MenuItemIcon icon={icon} />}
      {!icon && level > 1 && <MenuItemBullet />}
      <span className="menu-title">{title}</span>
      <span className={`menu-arrow ${level > 1 ? '' : 'd-lg-none'}`} />
    </span>
  )
}

export default MenuItemLink
