import _ from 'lodash'

import getErrorMessage from '../../../../services/getErrorMessage'

const KEYS_NAMES = {
  base: 'Cobrança',
  total: 'Valor total',
  due_date: 'Data de validade',
  email: 'E-mail do cliente',
  'payer.name': 'Nome completo do cliente',
  'payer.cpf_cnpj': 'CPF/CNPJ do cliente',
  'payer.address.zip_code': 'CEP do endereço de cobrança',
  'payer.address.number': 'Número do endereço de cobrança'
}

class InvoiceError extends Error {
  constructor(errors) {
    const { message } = getErrorMessage(errors, KEYS_NAMES)

    super(message)
    this.name = 'InvoiceError'
  }
}

export default InvoiceError
