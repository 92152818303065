import MenuItemLink from './ItemLink'
import MenuItemSubAccordion from './ItemSubAccordion'

const MenuItem = ({
  slug,
  title,
  items,
  level,
  content,
  component,
  ...rest
}) => {
  if (component) return <>{component}</>
  if (content)
    return <div className={`menu-item me-lg-1 ${slug}`}>{content}</div>

  if (items)
    return (
      <div
        data-ttz-menu-trigger="{default:'click', lg: 'hover'}"
        data-ttz-menu-placement={level > 1 ? 'right-start' : 'bottom-start'}
        className={`menu-item menu-lg-down-accordion menu-sub-lg-down-indention me-0 me-lg-2 ${slug}`}
      >
        <MenuItemLink title={title} level={level} {...rest} />
        <MenuItemSubAccordion items={items} level={level} />
      </div>
    )

  return (
    <div className={`menu-item me-0 me-lg-2 ${slug}`}>
      <MenuItemLink title={title} level={level} {...rest} />
    </div>
  )
}

export default MenuItem
