import { useEffect, useRef } from 'react'

const isDev = process.env.NODE_ENV == 'development'

const useInterval = (callback, { interval, lead }) => {
  const savedCallback = useRef(null)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    if (!isDev) {
      const tick = () => savedCallback.current()

      lead && tick()

      if (interval !== null) {
        const id = setInterval(tick, interval)

        return () => clearInterval(id)
      }
    }
  }, [interval])
}

export const ReleaseVersionRefreshMAnager = () => {
  useInterval(
    async () => {
      try {
        const res = await fetch('/api/build-id')
        const resJson = await res.json()
        const { buildId } = resJson

        if (buildId && process.env.BUILD_ID && buildId !== process.env.BUILD_ID)
          location.reload()
      } catch (error) {}
    },
    { interval: 60000 }
  )

  return <></>
}
